import React from 'react';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter, Form, } from 'reactstrap';
import { collection, doc, getDoc, query, where, orderBy, limit, addDoc, updateDoc, setDoc, } from 'firebase/firestore';
import { isEmpty, mapValues } from 'lodash';

import useFormState from '../hooks/useFormState';
import Field from '../Field';

const { entries } = Object;
const fields = () => {
  return {
    name: {
      type: 'boolean',
      label: '利用規約およびプライバシーポリシーに同意する',
      validations: {
        required: v => v === true,
      },
    },
  };
};

export default function AcceptionFormModal(props) {
  const { user } = props;
  const statedFields = useFormState({}, fields());
  const isUnsubmittable = Object.values(statedFields).some(_ => !_.isValid);
  const onSubmit = async (event) => {
    event.preventDefault();
    if(isUnsubmittable) return;

    await updateDoc(user.ref, { isAccepted: true });
  };

  return (
    <Modal isOpen>
      <ModalHeader>
        利用規約およびプライバシーポリシーへの同意
      </ModalHeader>
      <Form onSubmit={onSubmit}>
        <ModalBody>
          <ModalBody>
            <div className="mb-3">
              <a href="https://lava-planarian-695.notion.site/KHAKI-16a7b2467a8a4b84a8032d446f4861a4" target="_blank">
                利用規約およびプライバシーポリシー
                <span className="fas fa-external-link-alt ml-1" />
              </a>
            </div>
            {
              entries(statedFields).map(([fieldName, fieldSetting]) => (
                <Field
                  key={fieldName}
                  name={fieldName}
                  {...fieldSetting}
                />
              ))
            }
          </ModalBody>
        </ModalBody>
        <ModalFooter>
          <Button className="save" type="submit" color="primary" onClick={onSubmit} disabled={isUnsubmittable}>送信</Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};
