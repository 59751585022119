const { chunk, pick } = require('lodash');

const docToData = (doc) => {
  return { ...doc.data(), ...pick(doc, ['id', 'ref']), };
};

const getDocumentData = async (ref) => {
  return ref && docToData(await ref.get());
};

const getCollectionData = async (ref) => {
  return ref && (await ref.get()).docs.map(docToData);
};

const batch = async (db, data, f) => {
  let count = 0;
  return await chunk(data, 500).reduce(async (x, data, i) => {
    const prevs = await x;

    const batch = db.batch();
    const refs = data.map((_, i2) => f(batch, _, i * 500 + i2));
    await batch.commit();
    return [...prevs, ...refs];
  }, Promise.resolve([]));
};

module.exports = {
  docToData,
  getDocumentData,
  getCollectionData,
  batch,
};
