import React, { Component, Fragment } from 'react';
import { getAuth } from "firebase/auth";
import { Link } from 'react-router-dom';
import { Button, Navbar, Nav, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, } from 'reactstrap';
import Select from 'react-select';
import qs from 'qs';
import { format as formatDate, startOfMonth, endOfMonth, } from 'date-fns';
import { isEmpty, } from 'lodash';
import { toast } from 'react-toastify';
import classnames from 'classnames';
import { useToggle, } from 'react-use';
import copy from 'copy-to-clipboard';

import firebase from '../firebase';
import logo from '../images/logo_horizontal.png';
import CompanySyncButton from './CompanySyncButton';
import EditButton from './EditButton';
import NonFreeeCompanyFormModal from './modals/NonFreeeCompanyFormModal';
import { periodOfFiscalYear, fullPathWithParams, fiscalYearOfPeriod, pickSearch } from '../utils';
import env from '../env';

const auth = getAuth(firebase);
const { entries } = Object;

export default function HeaderNav (props) {
  const { links, user, match: { params: { companyId } }, location: { search }, company, admin = false, } = props;
  const [isOpen, toggleOpen] = useToggle(false);
  const onClickLogOut = async () => {
    if(!window.confirm('ログアウトしますか？')) return;
    await auth.signOut();
    window.location.href = '/';
  }
  const { isSyncing = false } = {};

  return (
    <header className="header-nav">
      <Navbar color={admin ? 'dark' : 'white'} dark={admin} expand="md">
        {
          admin ? (
            <Link to="/admin" className="navbar-brand">
              KHAKI Admin
            </Link>
          ) : (
            <Link to="/" className="navbar-brand font-weight-bold">
              <img src={logo} style={{ height: 35 }} />
            </Link>
          )
        }
        <div className="ml-auto d-flex align-items-center">
          {
            companyId && (
              <Fragment>
                {
                  company && (
                    <span className={classnames('ml-3', { 'text-white': admin })}>{company.display_name}</span>
                  )
                }
                {
                  company != null && (
                    !isEmpty(company.fiscalYears) && (
                      <div className="ml-1">
                        <CompanySyncButton color="link" />
                      </div>
                    )
                  )
                }
              </Fragment>
            )
          }
          <Nav navbar className="d-flex justify-content-end" style={{ minWidth: 150 }}>
            {
              user != null && (
                <UncontrolledDropdown tag="div">
                  <DropdownToggle nav caret>
                    {user.displayName || '(未設定)'}
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem tag={Link} to="/profile">
                      プロフィール
                    </DropdownItem>
                    <DropdownItem tag='a' href="https://kansapo.wraptas.site/a1c4f54731974fd0ae040edca42142de" target="_blank">
                      ヘルプ <span className="ml-1 fas fa-external-link-alt" />
                    </DropdownItem>
                    <DropdownItem onClick={onClickLogOut}>
                      ログアウト
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              )
            }
          </Nav>
        </div>
      </Navbar>
      {
        companyId && (
          <div className="d-flex flex-row bg-light p-2 text-nowrap flex-wrap gap-3">
            {
              entries(links).map(([name, { path, label }]) => {
                return (
                  <div key={name} className>
                    <Link className="text-white" to={`/companies/${companyId}/${path || name}`}>{label}</Link>
                  </div>
                );
              })
            }
          </div>
        )
      }
    </header>
  );
};
