import React from 'react';
import numeral from 'numeral';
import qs from 'qs';
import Encoding from 'encoding-japanese';

import { floatFormat, integerFormat, integerChangeFormat, } from './shared/util';

export function readFile(file, type = 'readAsText') {
  const reader = new FileReader();
  reader[type](file);
  return new Promise((resolve) => {
    reader.addEventListener('load', _ => resolve(_.target.result));
  });
};

export function fullPathWithParams(params, { pathname, search }) {
  const currentParams = qs.parse(decodeURI(search.slice(1)));
  const newParams = {
    ...currentParams,
    ...params
  };
  const newSearch = qs.stringify(newParams);
  return `${pathname}${newSearch ? `?${newSearch}` : ''}`;
};

export function yen(value) {
  return (
    <>
      <span className="mr-1">&yen;</span>
      <span>{integerFormat(value)}</span>
    </>
  );
}

export function formFloatFormat(value) {
  return numeral(value).format('0,0[.][000000]');
}

export function numberFormat(currency = 'jpy', value) {
  return (currency === 'jpy' ? integerFormat : floatFormat)(value);
}

export function encode (text, encoding = 'SJIS') {
  const code = Encoding.stringToCode(text);
  return new Uint8Array(Encoding.convert(code, encoding, 'UNICODE'));
};

export { floatFormat, integerFormat, integerChangeFormat, };
