const { isEmpty } = require('lodash');
const { isMultibyte, isEmail, } = require('validator');

const roles = {
  owner: 'オーナー',
  admin: '管理者',
  member: '一般',
};

const adminFields = () => {
  return {
    isSupporter: {
      label: 'サポーター',
      type: 'boolean',
      initialValue: false,
    },
  };
};

const fieldsToSignUp = () => {
  return {
    email: {
      type: 'string',
      label: 'メールアドレス',
      validations: {
        required: v => !isEmpty(v),
        format: v => !v || isEmail(v),
        notMultibyte: v => !v || !isMultibyte(v),
      },
    },
    displayName: {
      type: 'string',
      label: 'ユーザー名',
      validations: {
        required: v => !isEmpty(v),
      },
    },
  };
};
const fieldsToSignIn = () => {
  return {
    email: {
      type: 'string',
      label: 'メールアドレス',
      validations: {
        required: v => !isEmpty(v),
        format: v => !v || isEmail(v),
        notMultibyte: v => !v || !isMultibyte(v),
      },
    },
  };
};

exports.roles = roles;
exports.adminFields = adminFields;
exports.fieldsToSignUp = fieldsToSignUp;
exports.fieldsToSignIn = fieldsToSignIn;
