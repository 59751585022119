import React, { useState } from 'react';
import { httpsCallable } from 'firebase/functions';
import { collection, doc, getDoc, query, where, orderBy, limit, addDoc, updateDoc, setDoc, } from 'firebase/firestore';
import { Button, Form, Input } from 'reactstrap';
import { toast } from 'react-toastify';
import { useToggle } from 'react-use';
import { isEmail } from 'validator';
import copy from 'copy-to-clipboard';
import { mapValues, sortBy, pick, omit, get, } from 'lodash';
import Select from 'react-select';
import { useAsync } from 'react-use';

import firebase, { functions, db, } from '../../firebase';
import { canUpdateMember } from '../../shared/abilities';
import { fields, addingFields, } from '../../shared/models/member';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useFormState from '../hooks/useFormState';
import ModelFormModal from '../modals/ModelFormModal';
import SettingsPage from '../hocs/SettingsPage';
import ModalButton from '../ModalButton';
import Field from '../Field';
import { log } from '../../utils';
import { roles } from '../../shared/models/user';

const usersRef = collection(db, 'users');
const { entries } = Object;
const roleOptions = entries(roles).map(([k, v]) => ({ label: v, value: k, }));
const addCompanyMember = httpsCallable(functions, 'addCompanyMember');
const getCompanyMembers = httpsCallable(functions, 'getCompanyMembers');

export default SettingsPage(function CompanyMembers (props) {
  const { user, company, match: { params: { companyId } } } = props;
  const { value: members = [] } = useAsync(async () => {
    const { data: members, } = await getCompanyMembers({ companyId });
    return sortBy(members, [_ => Object.keys(roles).indexOf(_.role), 'email']);
  }, [company]);
  const [invitationToken, setInvitationToken] = useState('');
  const invitationUrl = `${window.location.origin}/confirmation?token=${invitationToken}&signUp=1`;
  const onClickCopy = (text) => {
    copy(text);
    toast.success('クリップボードにコピーしました');
  };
  const onClickRemove = async (member) => {
    if(!window.confirm('本当に除外しますか？')) return;

    await updateDoc(company.ref, { users: omit(company.users, member.id), });
    // NOTE: 上のupdateだけだと、client側のcompanyの変更が早く動いてしまい、getCompanyMembers関数が返すものが古いものになってしまう
    await updateDoc(company.ref, { updatedAt: new Date(), });
    toast.success('除外しました');
  };
  const onChangeRole = async (member, { value: role } = {}) => {
    if(role === 'owner' && !window.confirm('オーナーを変更しますか？（現在のオーナーは管理者になります）')) return;

    await updateDoc(company.ref, {
      [`users.${member.id}.role`]: role,
      ...(
        role === 'owner' && {
          [`users.${members.find(_ => _.role === 'owner').id}.role`]: 'admin',
        }
      ),
    });
    // NOTE: 上のupdateだけだと、client側のcompanyの変更が早く動いてしまい、getCompanyMembers関数が返すものが古いものになってしまう
    await updateDoc(company.ref, { updatedAt: new Date(), });
    toast.success('更新しました');
  };

  return (
    <div className="company-members container">
      {
        canUpdateMember(company, user) && (
          <div className="d-flex justify-content-end">
            {
              members.length >= (company.licenseCount || 5) ? (
                <div className="flex-fill alert alert-warning">
                  メンバー数の上限に達しています。
                </div>
              ) : (
                // <AddingForm company={company} user={user} setInvitationToken={setInvitationToken} />
                <ModalButton color="primary" title="追加する" content={_ => <AddingFormModalContent {..._} company={company} user={user} setInvitationToken={setInvitationToken} />}>
                  <span className="fas fa-plus mr-1" />
                  追加する
                </ModalButton>
              )
            }
          </div>
        )
      }
      {
        invitationToken && (
          <div className="alert alert-info my-2">
            <div>存在しないユーザーです。以下の招待URLからユーザー登録を依頼してください。</div>
            <div style={{ width: 500 }} className="mt-1 d-flex">
              <Input className="flex-grow-1 mr-2" readOnly defaultValue={invitationUrl} />
              <Button color="primary" onClick={onClickCopy.bind(null, invitationUrl)}>
                <span className="fas fa-copy" />
              </Button>
            </div>
          </div>
        )
      }
      <table className="table mt-4">
        <tbody>
          {
            members.map((member) => {
              const { id, role, enabledScreens, enabledRelatedCompanyIds, enabledPkgScreens, } = member;
              const onSubmitEdit = async (toggleModal, values) => {
                if(role !== 'owner' && values.role === 'owner' && !window.confirm('オーナーを変更しますか？（現在のオーナーは管理者になります）')) return;

                await updateDoc(company.ref, {
                  [`users.${id}`]: values,
                  ...(
                    values.role === 'owner' && {
                      [`users.${members.find(_ => _.role === 'owner').id}.role`]: 'admin',
                    }
                  ),
                });
                // NOTE: 上のupdateだけだと、client側のcompanyの変更が早く動いてしまい、getCompanyMembers関数が返すものが古いものになってしまう
                await updateDoc(company.ref, { updatedAt: new Date(), });
                toast.success('更新しました');
                toggleModal(false);
              };

              return (
                <tr key={id}>
                  <td>
                    <div className="d-flex gap-1 align-items-end">
                      <div>{member.displayName}</div>
                      <div className="text-muted small">&lt;{member.email}&gt;</div>
                    </div>
                  </td>
                  <td>
                    <span>{roles[role]}</span>
                  </td>
                  <td className="text-right text-nowrap">
                    <ModalButton Modal={ModelFormModal} modalProps={({ toggleModal }) => ({ title: '編集', fields: fields({ company, currentRole: role, }), values: { role, enabledScreens, enabledRelatedCompanyIds, enabledPkgScreens, }, onSubmit: onSubmitEdit.bind(null, toggleModal), })}>
                      <span className="fas fa-edit mr-1" />
                      編集
                    </ModalButton>
                    {
                      role !== 'owner' && user.id !== id && canUpdateMember(company, user) && (
                        <Button className="ml-1 bg-danger" onClick={onClickRemove.bind(null, { ...member, role })}>
                          <span className="fas fa-trash mr-1" />
                          除外
                        </Button>
                      )
                    }
                  </td>
                </tr>
              );
            })
          }
        </tbody>
      </table>
    </div>
  );
});

function AddingForm (props) {
  const { company, user, setInvitationToken, } = props;
  const [role, setRole] = useState('member');
  const [email, setEmail] = useState('');
  const [isAdding, toggleAdding] = useToggle();
  const onSubmitAddingForm = async (event) => {
    event.preventDefault();
    toggleAdding();
    try {
      const { data: { type, user: addedUser, token, } } = await addCompanyMember({ companyId: company.id, email, role, });
      ({
        add: () => {
          toast.success('追加しました');
        },
        invite: () => {
          setInvitationToken(token);
        },
      })[type]();
      setEmail('');
      setRole('member');
    } catch(e) {
      console.error(e);
      toast.error('失敗しました');
    }
    toggleAdding();
  };

  return (
    <Form onSubmit={onSubmitAddingForm} className="d-flex">
      <div style={{ width: 200 }}>
        <Select
          options={roleOptions}
          value={roleOptions.find(_ => _.value === role)}
          onChange={_ => setRole(_.value)}
          className="w-100"
        />
      </div>
      <Input className="ml-2" value={email} onChange={_ => setEmail(_.target.value)} style={{ width: 300 }} placeholder="メールアドレス" />
      <Button color="primary" className="ml-2" onClick={onSubmitAddingForm} disabled={isAdding || !isEmail(email)}>
        <span className="fas fa-plus mr-1" />
        追加する
      </Button>
    </Form>
  );
}

function AddingFormModalContent (props) {
  const { company, user, toggleModal, setInvitationToken, } = props;
  const statedFields = useFormState(null, addingFields(), false);
  const isUnsubmittable = Object.values(statedFields).some(_ => !_.isValid);
  const [isSubmitting, toggleSubmitting] = useToggle(false);
  const onSubmit = async (event) => {
    event.preventDefault();
    if(isUnsubmittable) return;

    toggleSubmitting(true);
    const values = mapValues(statedFields, 'value');
    const { data: { type, user: addedUser, token, } } = await addCompanyMember({ companyId: company.id, ...values, });
    const message = ({
      exists: 'すでにメンバーです',
      add: '追加しました',
      invite: '招待URLを発行しました',
    })[type];
    toast[type === 'exists' ? 'error' : 'success'](message);
    if(type === 'invite') {
      setInvitationToken(token);
    }
    toggleSubmitting(false);
    toggleModal(false);
  };

  return (
    <Form onSubmit={onSubmit}>
      {
        entries(statedFields).map(([fieldName, fieldSetting]) => (
          <Field
            key={fieldName}
            name={fieldName}
            values={mapValues(statedFields, 'value')}
            {...fieldSetting}
          />
        ))
      }
      <Button block className="save" type="submit" color="primary" onClick={onSubmit} disabled={isUnsubmittable || isSubmitting}>追加する</Button>
    </Form>
  );
}
