import React from 'react';
import { Route, Switch, Redirect } from 'react-router';

import AdminRoot from './components/pages/AdminRoot';
import AdminUsers from './components/pages/AdminUsers';
import AdminCompanies from './components/pages/AdminCompanies';
import AdminPlans from './components/pages/AdminPlans';
import Dashboard from './components/pages/Dashboard';
import CompanyRoot from './components/pages/CompanyRoot';
import CompanyDeferments from './components/pages/CompanyDeferments';
import CompanyRenewPlusPlans from './components/pages/CompanyRenewPlusPlans';
import CompanyGeneralSettings from './components/pages/CompanyGeneralSettings';
import CompanyMembers from './components/pages/CompanyMembers';
import CompanyPayment from './components/pages/CompanyPayment';
import Profile from './components/pages/Profile';
import Confirmation from './components/pages/Confirmation';

const routeObjs = [
  {
    exact: true,
    path: '/admin',
    component: AdminRoot,
  },
  {
    exact: true,
    path: '/admin/companies',
    component: AdminCompanies,
  },
  {
    exact: true,
    path: '/admin/users',
    component: AdminUsers,
  },
  {
    exact: true,
    path: '/admin/plans',
    component: AdminPlans,
  },
  {
    exact: true,
    path: '/',
    component: Dashboard,
  },
  {
    exact: true,
    path: '/companies/:companyId',
    component: _ => <Redirect to={`/companies/${_.match.params.companyId}/deferments?${_.location.search}`} />
  },
  {
    exact: true,
    path: '/companies/:companyId/home',
    component: CompanyRoot,
  },
  {
    exact: true,
    path: '/companies/:companyId/deferments',
    component: CompanyDeferments,
  },
  {
    exact: true,
    path: '/companies/:companyId/renewPlusPlans',
    component: CompanyRenewPlusPlans,
  },
  {
    exact: true,
    path: '/companies/:companyId/settings',
    component: CompanyGeneralSettings,
  },
  {
    exact: true,
    path: '/companies/:companyId/settings/members',
    component: CompanyMembers,
  },
  {
    exact: true,
    path: '/companies/:companyId/settings/payment',
    component: CompanyPayment,
  },
  {
    exact: true,
    path: '/profile',
    component: Profile,
  },
  {
    exact: true,
    path: '/confirmation',
    component: Confirmation,
  },
]

export default function routes (extraProps) {
  return (
    <Switch>
      {
        routeObjs.map((route, i) => {
          const { exact, path, render, props } = route;
          return (
            <Route exact={exact} path={path} render={render || (_ =>
              <route.component {..._} {...extraProps} {...props} />
            )} key={i} />
          );
        })
      }
    </Switch>
  );
};
