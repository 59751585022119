const { invert, mapKeys } = require('lodash');

const jas = {
  accountItemName: '科目',
  section: '部門',
  partner: '取引先',
  item: '品目',
  tags: 'メモ',
  segment1: 'セグメント1',
  segment2: 'セグメント2',
  segment3: 'セグメント3',
  tax: '税区分',
  description: '備考',
  issueDate: '発生日',
  dealId: '取引ID',
  issueAmount: '発生額',
  leftAmount: '残高',
  monthAmountType: '数値種別',
  monthRenewsAmount: '更新額',
  balance: '残高',
};

const invertedJas = invert(jas);

const mapKeysToJa = (_, _jas = {}) => mapKeys(_, (v, k) => {
  const [, main, sub] = k.match(/^([^_]*)(.*)/) || [];
  return (({ ...jas, ..._jas })[main] || main) + sub;
});

const mapKeysFromJa = (_, _jas = {}) => mapKeys(_, (v, k) => {
  const [, main, sub] = k.match(/^([^_]*)(.*)/) || [];
  return (({ ...invertedJas, ..._jas })[main] || main) + sub;
});

const validations = {
  general: {
    required: '必須項目です',
    greaterThanOrEqualTo0: '0以上で指定してください。',
    greaterThan0: '1以上で指定してください。',
    lessThanOrEaualToX: '指定可能な数値を超えています',
    samePassword: 'パスワードと一致しません',
    format: '形式が正しくありません',
    inRange: '範囲内で指定してください',
    greaterThanStart: '開始時刻以降で指定してください',
    exclusive: '他と重なっています',
    minLength: '長さが不足しています',
    exists: '存在しません',
    finite: '数値を入力してください',
    requiredInSomeCase: '入力してください',
    unique: '他に存在します',
  },
  cfMappingItem: {
    pkgCategory: { required: '値が正しくありません', },
    columnKey: { required: '値が正しくありません', },
    cfChangeTypeId: { required: '値が正しくありません', },
    cfAccountItemId: { required: '値が正しくありません', },
  },
};

exports.mapKeysToJa = mapKeysToJa;
exports.mapKeysFromJa = mapKeysFromJa;
exports.validations = validations;
exports.jas = jas;
