import { useState, useEffect } from 'react';
import { getAuth } from "firebase/auth";

import firebase from '../../firebase';

const auth = getAuth(firebase);

export default function useFirebaseUser () {
  const [firebaseUser, setFirebaseUser] = useState();
  const [emailVerified, setEmailVerified] = useState(false);
  const reloadEmailVerified = async () => {
    if(firebaseUser == null) return;

    firebaseUser && await firebaseUser.reload();
    setEmailVerified(auth.currentUser.emailVerified);
  };
  useEffect(() => {
    auth.onAuthStateChanged((firebaseUser) => {
      setFirebaseUser(firebaseUser);
      setEmailVerified(firebaseUser && firebaseUser.emailVerified);
    });
  }, []);

  return { firebaseUser, emailVerified, reloadEmailVerified };
};
