import React, { Component, useEffect, } from 'react';
import { toast } from 'react-toastify';

import HeaderNav from '../HeaderNav';

export default function AdminPageHOC(WrappedComponent) {
  return function AdminPage (props) {
    const { user, history, } = props;
    const checkAdmin = () => {
      if(!user.admin) {
        history.push('/');
        toast.error('Not authorized');
      }
    };

    useEffect(() => {
      checkAdmin();
    });
    return !!user.admin && (
      <div>
        <HeaderNav {...{ ...props }} admin />
        <WrappedComponent
          {...{ ...props, }}
        />
      </div>
    );
  };
}
