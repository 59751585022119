const { invert, invoke, sum, sumBy, uniq, keyBy, } = require('lodash');

const accountItemCategories = [
  ...[
    ...[
      { name: '現金・預金', parent: '流動資産', closes: true, },
      { name: '売上債権', parent: '流動資産', closes: true, },
      { name: '棚卸資産', parent: '流動資産', closes: true, },
      { name: '他流動資産', parent: '流動資産', closes: true, },
      { name: '流動資産', parent: '資産', },
      { name: '有価証券', parent: '資産', },
      { name: '有形固定資産', parent: '固定資産', },
      { name: '無形固定資産', parent: '固定資産', },
      { name: '投資その他の資産', parent: '固定資産', },
      { name: '投資等', parent: '固定資産', },
      { name: '固定資産', parent: '資産', },
      { name: '繰延資産', parent: '資産', },
      { name: '諸口', parent: '資産', },
      { name: '資産', },
    ].map(_ => ({ ..._, direction: 'debit', })),
    ...[
      { name: '仕入債務', parent: '流動負債', closes: true, },
      { name: '他流動負債', parent: '流動負債', closes: true, },
      { name: '流動負債', parent: '負債', },
      { name: '固定負債', parent: '負債', },
      { name: '負債', parent: '負債及び純資産', },
      { name: '資本金', roma: 'shihon', parent: '株主資本', closesItems: true, },
      { name: '新株式申込証拠金', roma: 'shinkabushiki', parent: '株主資本', closesItems: true, },
      { name: '資本準備金', roma: 'shihonjunbi', parent: '資本剰余金', closes: true, closesItems: true, },
      { name: 'その他資本剰余金', roma: 'sonotashihonjouyo', parent: '資本剰余金', closes: true, closesItems: true, },
      { name: '資本剰余金', roma: 'shihonjouyo', parent: '株主資本', closesItems: true, compute: (_, isSum) => computeCategoryAmount('資本準備金', _, isSum) + computeCategoryAmount('その他資本剰余金', _, isSum) },
      { name: '利益準備金', roma: 'riekijunbi', parent: '利益剰余金', closes: true, closesItems: true, },
      { name: '任意積立金', roma: 'ninitsumitate', parent: '利益剰余金', closes: true, closesItems: true, },
      { name: '繰越利益剰余金', roma: 'kurikoshijouyo', parent: '利益剰余金', closes: true, closesItems: true, },
      { name: '当期純損益金額', roma: 'toukijunsoneki', parent: '利益剰余金', closes: true, closesItems: true, compute: (_, isSum) => computeCategoryAmount('親会社株主に帰属する当期純利益', _, isSum), },
      { name: '利益剰余金', roma: 'riekijouyo', parent: '株主資本', closesItems: true, compute: (_, isSum) => computeCategoryAmount('利益準備金', _, isSum) + computeCategoryAmount('任意積立金', _, isSum) + computeCategoryAmount('繰越利益剰余金', _, isSum) + computeCategoryAmount('当期純損益金額', _, isSum) },
      { name: '自己株式', roma: 'jikokabushiki', parent: '株主資本', closesItems: true, },
      { name: '自己株式申込証拠金', roma: 'jikokabushikishouko', parent: '株主資本', closesItems: true, },
      { name: '株主資本', roma: 'kabunushishihon', parent: '純資産', compute: (_, isSum) => computeCategoryAmount('資本金', _, isSum) + computeCategoryAmount('新株式申込証拠金', _, isSum) + computeCategoryAmount('資本剰余金', _, isSum) + computeCategoryAmount('利益剰余金', _, isSum) + computeCategoryAmount('自己株式', _, isSum) + computeCategoryAmount('自己株式申込証拠金', _, isSum) },
      { name: '他有価証券評価差額金', roma: 'yuukashoukensagaku', parent: '評価・換算差額等', closesItems: true, },
      { name: '繰延ヘッジ損益', roma: 'kurinobehejji', parent: '評価・換算差額等', closesItems: true, },
      { name: '土地再評価差額金', roma: 'tochisaihyoukasagaku', parent: '評価・換算差額等', closesItems: true, },
      { name: '為替換算調整勘定', roma: 'kawasekansan', parent: '評価・換算差額等', closesItems: true, },
      { name: '退職給付に係る調整累計額', ciName: '退職給付に係る調整額', roma: 'taishokuchousei', closesItems: true, parent: '評価・換算差額等', },
      { name: '評価・換算差額等', roma: 'hyoukakansan', parent: '純資産', compute: (_, isSum) => computeCategoryAmount('他有価証券評価差額金', _, isSum) + computeCategoryAmount('繰延ヘッジ損益', _, isSum) + computeCategoryAmount('土地再評価差額金', _, isSum) + computeCategoryAmount('為替換算調整勘定', _, isSum) + computeCategoryAmount('退職給付に係る調整累計額', _, isSum) },
      { name: '新株予約権', roma: 'shinkabuyoyaku', parent: '純資産', closesItems: true, },
      { name: '非支配株主持分', roma: 'hishihaimochibun', parent: '純資産', closesItems: true, },
      { name: '純資産', roma: 'junshisan', parent: '負債及び純資産', compute: (_, isSum) => computeCategoryAmount('株主資本', _, isSum) + computeCategoryAmount('評価・換算差額等', _, isSum) + computeCategoryAmount('非支配株主持分', _, isSum) + computeCategoryAmount('新株予約権', _, isSum) },
      { name: '負債及び純資産', compute: (_, isSum) => computeCategoryAmount('負債', _, isSum) + computeCategoryAmount('純資産', _, isSum) },
    ].map(_ => ({ ..._, direction: 'credit', })),
  ].map(_ => ({ ..._, type: 'bs', denominatorCategoryName: '資産', })),
  ...[
    { name: '売上高', direction: 'credit', closesItems: true,  denominatorCategoryName: '売上高', },

    // NOTE: 以下、売上原価が親だが、一旦parentをなくす。売上原価は試算表の単独の行を使う。
    { name: '期首商品棚卸', direction: 'debit', closes: true, denominatorCategoryName: '売上原価', },
    { name: '当期商品仕入', direction: 'debit', closes: true,  denominatorCategoryName: '売上原価', },
    { name: '他勘定振替高(商)', direction: 'debit', closes: true, denominatorCategoryName: '売上原価', },
    { name: '期末商品棚卸', direction: 'credit', closes: true, denominatorCategoryName: '売上原価', },
    { name: '商品売上原価', direction: 'debit', closes: true, denominatorCategoryName: '売上原価', computable: true, compute: (_, isSum) => computeCategoryAmount('期首商品棚卸', _, isSum) + computeCategoryAmount('当期商品仕入', _, isSum) + computeCategoryAmount('他勘定振替高(商)', _, isSum) - computeCategoryAmount('期末商品棚卸', _, isSum), sum: ['期首商品棚卸', '当期商品仕入', '他勘定振替高(商)', '期末商品棚卸'], },
    { name: '期首製品棚卸', direction: 'debit', closes: true, denominatorCategoryName: '売上原価', },
    { name: '当期製品製造原価', direction: 'debit', closes: true, denominatorCategoryName: '売上原価', computable: true, compute: (_, isSum) => computeCategoryAmount('製造原価', _, isSum), },
    { name: '他勘定振替高(製)', direction: 'debit', closes: true, denominatorCategoryName: '売上原価', },
    { name: '期末製品棚卸', direction: 'credit', closes: true, denominatorCategoryName: '売上原価', },
    { name: '製品売上原価', direction: 'debit', closes: true, denominatorCategoryName: '売上原価', computable: true, compute: (_, isSum) => computeCategoryAmount('期首製品棚卸', _, isSum) + computeCategoryAmount('当期製品製造原価', _, isSum) + computeCategoryAmount('他勘定振替高(製)', _, isSum) - computeCategoryAmount('期末製品棚卸', _, isSum), sum: ['期首製品棚卸', '当期製品製造原価', '他勘定振替高(製)', '期末製品棚卸'], },
    { name: '売上原価', direction: 'debit', closesItems: true, denominatorCategoryName: '売上原価', computable: true, compute: (_, isSum) => computeCategoryAmount('商品売上原価', _, isSum) + computeCategoryAmount('製品売上原価', _, isSum), },
    { name: '振替', direction: 'debit', closes: true, denominatorCategoryName: '売上原価', },
    { name: '売上総利益', direction: 'credit', computable: true, compute: (_, isSum) => computeCategoryAmount('売上高', _, isSum) - computeCategoryAmount('売上原価', _, isSum), sum: ['売上高', '売上原価'], },
    { name: '販売管理費', direction: 'debit', denominatorCategoryName: '販売管理費', },
    { name: '営業利益', direction: 'credit', computable: true, compute: (_, isSum) => computeCategoryAmount('売上総利益', _, isSum) - computeCategoryAmount('販売管理費', _, isSum), sum: ['売上総利益', '販売管理費'], },
    { name: '営業外収益', direction: 'credit', denominatorCategoryName: '営業外収益', },
    { name: '営業外費用', direction: 'debit', denominatorCategoryName: '営業外費用', },
    { name: '経常利益', direction: 'credit', computable: true, compute: (_, isSum) => computeCategoryAmount('営業利益', _, isSum) + computeCategoryAmount('営業外収益', _, isSum) - computeCategoryAmount('営業外費用', _, isSum), sum: ['営業利益', '営業外収益', '営業外費用'], },
    { name: '特別利益', direction: 'credit', denominatorCategoryName: '特別利益', },
    { name: '特別損失', direction: 'debit', denominatorCategoryName: '特別損失', },
    { name: '税引前当期純利益', direction: 'credit', computable: true, compute: (_, isSum) => computeCategoryAmount('経常利益', _, isSum) + computeCategoryAmount('特別利益', _, isSum) - computeCategoryAmount('特別損失', _, isSum), sum: ['経常利益', '特別利益', '特別損失'], },
    { name: '法人税等', direction: 'debit', },
    { name: '法人税等調整額', direction: 'debit', },
    { name: '当期純利益', direction: 'credit', computable: true, compute: (_, isSum) => computeCategoryAmount('税引前当期純利益', _, isSum) - computeCategoryAmount('法人税等', _, isSum) - computeCategoryAmount('法人税等調整額', _, isSum), sum: ['税引前当期純利益', '法人税等', '法人税等調整額'], },
    { name: '非支配株主持分損益', direction: 'debit', },
    { name: '親会社株主に帰属する当期純利益', direction: 'credit', computable: true, compute: (_, isSum) => computeCategoryAmount('当期純利益', _, isSum) - computeCategoryAmount('非支配株主持分損益', _, isSum), sum: ['当期純利益', '非支配株主持分損益'], },
  ].map(_ => ({ ..._, type: 'pl', })),
  ...[
    { name: '期首原材料棚卸', direction: 'debit', closes: true, },
    { name: '当期原材料仕入高', direction: 'debit', closes: true, },
    { name: '期末原材料棚卸', direction: 'credit', closes: true, },
    { name: '材料費', direction: 'debit', closes: true, computable: true, compute: (_, isSum) => computeCategoryAmount('期首原材料棚卸', _, isSum) + computeCategoryAmount('当期原材料仕入高', _, isSum) - computeCategoryAmount('期末原材料棚卸', _, isSum), sum: ['期首原材料棚卸', '当期原材料仕入高', '期末原材料棚卸'], },
    { name: '労務費', direction: 'debit', closes: true, },
    { name: '製造経費', direction: 'debit', closes: true, },
    { name: '期首仕掛品棚卸', direction: 'debit', closes: true, },
    { name: '総製造費用', direction: 'debit', closes: true, computable: true, compute: (_, isSum) => computeCategoryAmount('材料費', _, isSum) + computeCategoryAmount('労務費', _, isSum) + computeCategoryAmount('製造経費', _, isSum), sum: ['材料費', '労務費', '製造経費'], },
    { name: '期末仕掛品棚卸', direction: 'credit', closes: true, },
    { name: '製造原価', direction: 'debit', closes: true, computable: true, compute: (_, isSum) => computeCategoryAmount('期首仕掛品棚卸', _, isSum) + computeCategoryAmount('総製造費用', _, isSum) - computeCategoryAmount('期末仕掛品棚卸', _, isSum), sum: ['期首仕掛品棚卸', '総製造費用', '期末仕掛品棚卸'], },
  ].map(_ => ({ ..._, type: 'cr', })),
].map((_category) => {
  const category = {
    ..._category,
    id: _category.name,
    key: _ => [_category.type, _category.name].join('--'),
    directionValue: ({ debit: 1, credit: -1 })[_category.direction],
    parents: _ => categoryParents(category),
    indent: _ => category.parents().length,
    descendants: _ => accountItemCategories.filter(_ => _.parents().includes(category.name)),
    selfAndDescendants: _ => [category, ...category.descendants()],
    descendantsAndSelf: _ => [...category.descendants(), category],
    selfAndParents: _ => [category, ...category.parents()],
    children: _ => accountItemCategories.filter(_ => _.parent === category.name),
    compute: (amountsByCategory, isSum) => {
      return isSum ? (
        category.sum(amountsByCategory, isSum)
      ) : (
        _category.compute ? (
          _category.compute(amountsByCategory, isSum)
        ) : (amountsByCategory[category.name] || 0)
      );
    },
    sum: _category.sum ? ((amountsByCategory, isSum) => sumBy(_category.sum, _ => accountItemCategoriesByName[_].compute(amountsByCategory, true))) : _category.compute || (amountsByCategory => amountsByCategory[category.name]),
    disclosureName: _ => _category.closes ? invoke(accountItemCategoriesByName, [_category.parent, 'disclosureName']) : _category.name,
    ratio: (item, categoryItemsByCategory) => item.conclusiveAmount / category.denominator(item, categoryItemsByCategory),
    denominator: (item, categoryItemsByCategory) => categoryItemsByCategory[category.denominatorCategoryName]?.conclusiveAmount,
  };
  return category;
});
const accountItemCategoryNames = accountItemCategories.map(_ => _.name);
const accountItemCategoriesByName = keyBy(accountItemCategories, 'name');
const accountItemCategoryParentNames = uniq(accountItemCategories.map(_ => _.parent));
const categoryParents = (category) => {
  return category.parent ?
    [category.parent, ...categoryParents(accountItemCategoriesByName[category.parent])]
  : [];
};
const accountItemCategoryChildren = accountItemCategories.filter(_ => !accountItemCategoryParentNames.includes(_.name))
const computeCategoryAmount = (categoryName, amountsGroupedByCategory, isSum) => {
  return accountItemCategoriesByName[categoryName][isSum ? 'sum' : 'compute'](amountsGroupedByCategory, isSum);
};

const documentTypes = {
  bs: { label: '貸借対照表', },
  pl: { label: '損益計算書', },
  cr: { label: '製造原価報告書', },
};

const screens = {
  deferments: { label: '+更新プラス', showsInHeader: true, },
  renewPlusPlans: { label: '+更新プラス プラン', },
  settings: { label: '設定', showsInHeader: true, },
};

const freeeFieldMapping = {
  tax_code: 'taxId',
  account_item_id: 'accountItemId',
  partner_id: 'partnerId',
  section_id: 'sectionId',
  item_id: 'itemId',
  tag_ids: 'tagIds',
  segment_1_tag_id: 'segment1Id',
  segment_2_tag_id: 'segment2Id',
  segment_3_tag_id: 'segment3Id',
  description: 'description',
};
const invertedFreeeFieldMapping = invert(freeeFieldMapping);

const freeeMasters = {
  accountItem: {
    label: '勘定科目',
    plural: 'accountItems',
  },
  tax: {
    label: '税区分',
    plural: 'taxes',
  },
  partner: {
    label: '取引先',
    plural: 'partners',
  },
  section: {
    label: '部門',
    plural: 'sections',
  },
  item: {
    label: '品目',
    plural: 'items',
  },
  tag: {
    label: 'メモタグ',
    plural: 'tags',
  },
  segment1: {
    label: 'セグメント1',
    plural: 'segment1s',
  },
  segment2: {
    label: 'セグメント2',
    plural: 'segment2s',
  },
  segment3: {
    label: 'セグメント3',
    plural: 'segment3s',
  },
};

const colors = [
  '#66c2a5',
  '#ffd92f',
  '#fc8d62',
  '#8da0cb',
  '#e78ac3',
  '#a6d854',
  '#e5c494',
  '#b3b3b3',
];

const noSelect = {
  id: '0',
  name: '未選択',
};

exports.colors = colors;
exports.accountItemCategories = accountItemCategories;
exports.accountItemCategoryNames = accountItemCategoryNames;
exports.accountItemCategoriesByName = accountItemCategoriesByName;
exports.accountItemCategoryChildren = accountItemCategoryChildren;
exports.documentTypes = documentTypes;
exports.screens = screens;
exports.freeeFieldMapping = freeeFieldMapping;
exports.invertedFreeeFieldMapping = invertedFreeeFieldMapping;
exports.freeeMasters = freeeMasters;
exports.noSelect = noSelect;
