import React from 'react';
import { Link } from 'react-router-dom';
import { query, orderBy, collection, doc } from 'firebase/firestore';
import { format as formatDate } from 'date-fns';

import AdminPage from '../hocs/AdminPage';
import firebase, { db } from '../../firebase';
import { adminFields, } from '../../shared/models/user';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import ModelFormModal from '../modals/ModelFormModal';
import EditButton from '../EditButton';


export default AdminPage(function AdminCompanies(props) {
  const users = useCollectionSubscription(query(collection(db, 'users'), orderBy('email')));

  return (
    <div className="admin-users">
      <div className="container-fluid py-5">
        <div className="d-flex justify-content-center mb-2">
          <h3>ユーザー管理</h3>
        </div>
        <table className="table mt-5 table-bordered">
          <thead className="thead-light">
            <tr>
              <th>Email</th>
              <th>ユーザー名</th>
              <th>Admin</th>
              <th>サポーター</th>
              <th>登録日時</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {
              users.map((user) => {
                const { id, ref, email, displayName, admin = false, isSupporter = false, createdAt, } = user;
                return (
                  <tr key={id} id={id}>
                    <td>
                      {email}
                    </td>
                    <td>
                      {displayName}
                    </td>
                    <td>
                      {admin && 'Admin'}
                    </td>
                    <td>
                      {isSupporter && 'サポーター'}
                    </td>
                    <td>
                      {createdAt != null && formatDate(createdAt.toDate(), 'yyyy/MM/dd HH:mm')}
                    </td>
                    <td>
                      <div className="text-nowrap d-flex justify-content-end gap-1">
                        <EditButton itemRef={ref} FormModal={ModelFormModal} formProps={{ title: 'ユーザー 編集', fields: adminFields, }} />
                      </div>
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  );
});
