const { mapValues, orderBy, groupBy, sumBy, range, isEmpty, } = require('lodash');
const { getYear, addMinutes, endOfMonth, eachMonthOfInterval, eachYearOfInterval, addYears, addMonths, format: formatDate, parseISO, startOfYear, endOfYear, } = require('date-fns');

const { entries } = Object;
const timezoneOffset = new Date().getTimezoneOffset() + 540;

const onStateChanged = (accountItemsById, prevStatedFields, statedFields) => {
  if(prevStatedFields?.accountItemId.value !== statedFields.accountItemId.value) {
    statedFields.setValues({
      taxId: accountItemsById[statedFields.accountItemId.value]?.default_tax_code.toString(),
    });
  }
};

const renewUpdateFields = ({ company, deal, detail, detailRenews, targetRenewIds, renewsTotalAmount, accountItems, taxes, sections, items, tags, segment1s, segment2s, segment3s, }) => {
  const noTargetRenews = detailRenews.filter(_ => !targetRenewIds.includes(_.id));
  const maxAmount = (detail.amount - sumBy(noTargetRenews, _ => sumBy(_.details, 'amount'))) / targetRenewIds.length;
  return {
    amount: {
      label: '金額',
      type: 'integer',
      validations: {
        greaterThan0: v => v == null || v > 0,
        lessThanOrEaualToX: v => v == null || v <= maxAmount,
      },
    },
    updateDate: {
      label: '日付',
      type: 'date',
      inputProps: {
        showYearDropdown: true,
        dropdownMode: 'select',
      },
    },
    accountItemId: {
      label: '勘定科目',
      type: 'select',
      options: accountItems.map(_ => ({ label: _.name, value: _.id })),
    },
    taxId: {
      label: '税区分',
      type: 'select',
      options: taxes.filter(_ => _.available).map(_ => ({ label: _.name_ja, value: _.id })),
    },
    sectionId: {
      label: '部門',
      type: 'select',
      options: sections.map(_ => ({ label: _.name, value: _.id })),
    },
    itemId: {
      label: '品目',
      type: 'select',
      options: items.map(_ => ({ label: _.name, value: _.id })),
    },
    tagIds: {
      label: 'メモタグ',
      type: 'multiSelect',
      options: tags.map(_ => ({ label: _.name, value: _.id })),
      initialValue: (detail.tag_ids || []),
    },
    ...(
      company.usesSegment1 && {
        segment1Id: {
          label: 'セグメント1',
          type: 'select',
          options: segment1s.map(_ => ({ label: _.name, value: _.id })),
        },
      }
    ),
    ...(
      company.usesSegment2And3 && {
        segment2Id: {
          label: 'セグメント2',
          type: 'select',
          options: segment2s.map(_ => ({ label: _.name, value: _.id })),
        },
        segment3Id: {
          label: 'セグメント3',
          type: 'select',
          options: segment3s.map(_ => ({ label: _.name, value: _.id })),
        },
      }
    ),
    description: {
      label: '備考',
      type: 'string',
    },
  };
};

const computeIsRunOut = (deal) => {
  const renewsGroupedByTargetId = groupBy(deal.renews, 'renew_target_id');
  return deal.details.every((detail) => {
    const detailRenews = renewsGroupedByTargetId[detail.id] || [];
    return detail.amount <= sumBy(detailRenews.flatMap(_ => _.details), 'amount');
  });
};

const computeRunOutOn = (deal) => {
  return computeIsRunOut(deal) ? new Date(orderBy(deal.renews, 'update_date', 'desc')[0].update_date) : addYears(new Date(deal.issue_date), 50);
};

const computeRunningYears = (deal) => {
  return eachYearOfInterval({
    start: new Date(deal.issue_date),
    end: computeRunOutOn(deal),
  }).map(_ => getYear(addMinutes(endOfYear(_), -timezoneOffset)));
};

const computeMonthLimit = (plan, company) => {
  const monthLimit = (plan?.isFree && new Date() <= company.renewPlusTrialExpiredAt?.toDate()) ? Infinity : plan?.value1 || 0;
  return monthLimit;
};

const computeLimitedDeals = (deals, plan, company) => {
  const monthLimit = computeMonthLimit(plan, company);
  const dealsGroupByMonth = groupBy(deals, _ => _.issue_date.slice(0, 7));
  const limitedDeals = Object.values(mapValues(dealsGroupByMonth, _ => _.slice(0, monthLimit))).flat();
  return limitedDeals;
};

exports.renewUpdateFields = renewUpdateFields;
exports.computeIsRunOut = computeIsRunOut;
exports.computeRunOutOn = computeRunOutOn;
exports.computeRunningYears = computeRunningYears;
exports.onStateChanged = onStateChanged;
exports.computeMonthLimit = computeMonthLimit;
exports.computeLimitedDeals = computeLimitedDeals;
