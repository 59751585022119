import React, { useEffect, } from 'react';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter, } from 'reactstrap';
import { httpsCallable, } from 'firebase/functions';
import { useHistory, useLocation, } from 'react-router';
import { omit, mapValues, isFunction, } from 'lodash';
import { useToggle } from 'react-use';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import retry from 'async-retry';
import qs from 'qs';
import { toast } from 'react-toastify';

import { functions, } from '../../firebase';
import env from '../../env';
import useQueryParams from '../hooks/useQueryParams';
import CheckoutForm from '../forms/CheckoutForm';
import ProgressButton from '../ProgressButton';
import OverlayLoading from '../OverlayLoading';

const { entries } = Object;
const stripePromise = loadStripe(env('STRIPE_PUBLIC_KEY'));
const syncIncompleteSubscription = httpsCallable(functions, 'syncIncompleteSubscription');
const cancelIncompleteSubscription = httpsCallable(functions, 'cancelIncompleteSubscription');

export default function StripeSubscriptionCompleteModal(props) {
  const { type, company, incompleteSubscription, } = props;
  const clientSecret = incompleteSubscription?.source?.latest_invoice?.payment_intent?.client_secret;
  const history = useHistory();
  const location = useLocation();
  const queryParams = useQueryParams();
  const onClickCancel = async () => {
    await retry(async () => {
      await cancelIncompleteSubscription({ companyId: company.id, type, });
    }, { retries: 2 });
  };
  useEffect(() => {
    if(incompleteSubscription != null && queryParams.payment_intent_client_secret) {
      (async () => {
        await retry(_ => syncIncompleteSubscription({ companyId: company.id, type, }), { retries: 2 });
        history.replace(location.pathname + '?' + qs.stringify({ ...omit(queryParams, ['payment_intent', 'redirect_status', 'payment_intent_client_secret']), }));
      })();
    }
  }, [incompleteSubscription, queryParams.payment_intent_client_secret]);
  const onError = (error) => {
    toast.error(error.message);
  }

  return incompleteSubscription != null && (
    <Modal isOpen>
      <ModalHeader>
        お支払い方法の設定
      </ModalHeader>
      <ModalBody>
        {
          clientSecret && (
            <Elements stripe={stripePromise} options={{ clientSecret }}>
              <CheckoutForm onError={onError} clientSecret={clientSecret} />
            </Elements>
          )
        }
        <div className="d-flex mt-1 justify-content-end">
          <ProgressButton color="link" size="sm" className="text-muted" process={onClickCancel}>
            キャンセル
          </ProgressButton>
        </div>
        <OverlayLoading isOpen={queryParams.payment_intent_client_secret} label="処理中..." />
      </ModalBody>
    </Modal>
  );
};
