import React, { useEffect, useState, } from 'react'
import { collection, doc, getDoc, getDocs, query, where, orderBy, limit, addDoc, updateDoc, setDoc, } from 'firebase/firestore';
import { Button, Form, Input } from 'reactstrap';
import Select from 'react-select';
import { pick, pickBy, keyBy } from 'lodash';

import useCollectionSubscription from './hooks/useCollectionSubscription';
import { fields } from '../shared/models/defermentTemplate';
import useCompany from './hooks/useCompany';
import ModalButton from './ModalButton';
import DefermentTemplates from './DefermentTemplates';

export default function DefermentTemplateSelector (props) {
  const { masters, onSelect, ...extraProps } = props;
  const company = useCompany();
  const [defermentTemplateId, setDefermentTemplateId] = useState(null);
  const defermentTemplates = useCollectionSubscription(query(collection(company.ref, 'defermentTemplates'), orderBy('createdAt')));
  const defermentTemplatesById = keyBy(defermentTemplates, 'id');
  const defermentTemplateOptions = defermentTemplates.map(_ => ({ label: _.name, value: _.id }));
  const onSubmit = async () => {
    const _fields = fields({ company, ...masters });
    const values = pickBy(pick(defermentTemplatesById[defermentTemplateId], Object.keys(_fields)), (v, k) => {
      return {
        string: v?.length > 0,
        select: v != null,
        multiSelect: v?.length > 0,
        integer: v != null,
      }[_fields[k].type];
    });
    await onSelect(values);
    await new Promise(_ => setTimeout(_, 100));
    await onSelect(values); // NOTE: 税区分が科目変更によって選択されてしまうので、選び直す
    setDefermentTemplateId(null);
  };

  return (
    <div {...extraProps}>
      <div className="d-flex flex-column align-items-end">
        <ModalButton className="p-0" size="sm" color="link" title="按分テンプレート" content={_ => <DefermentTemplates masters={masters} />}>
          <span className="fas fa-gear mr-1" />
          按分テンプレートの設定
        </ModalButton>
        <Form onSubmit={onSubmit} className="d-flex gap-1 align-items-end">
          <div style={{ width: 250 }}>
            <Select
              options={defermentTemplateOptions}
              value={defermentTemplateOptions.find(_ => _.value === defermentTemplateId) || null}
              onChange={_ => setDefermentTemplateId(_.value)}
              className="w-100"
              placeholder="按分テンプレートを選択"
            />
          </div>
          <Button onClick={onSubmit} disabled={defermentTemplateId == null}>
            適用
          </Button>
        </Form>
      </div>
    </div>
  );
};

