import React from 'react';
import { Button, Form, } from 'reactstrap';
import { mapValues } from 'lodash';
import { useToggle } from 'react-use';

import { fieldsToSignIn as fields } from '../../shared/models/user';
import useFormState from '../hooks/useFormState';
import Field from '../Field';
import PasswordResetFormModal from '../modals/PasswordResetFormModal';

const { entries } = Object;

export default function SignInForm(props) {
  const { isOpen, values, } = props;
  const [isProcessing, toggleProcessing] = useToggle(false);
  const [showsPasswordResetFormModal, togglePasswordResetFormModal] = useToggle();
  const statedFields = useFormState(values, fields(), isOpen);
  const isUnsubmittable = Object.values(statedFields).some(_ => !_.isValid);
  const onSubmit = async (event) => {
    event.preventDefault();
    if(isUnsubmittable) return;

    toggleProcessing(true);
    await props.onSubmit({ ...mapValues(statedFields, 'value') });
    toggleProcessing(false);
  };

  return (
    <Form onSubmit={onSubmit}>
      <div>
        {
          entries(statedFields).map(([fieldName, fieldSetting]) => (
            <Field
              hiddenRequiredLabel
              key={fieldName}
              name={fieldName}
              {...fieldSetting}
            />
          ))
        }
      </div>
      <div>
        <Button block type="submit" color="primary" onClick={onSubmit} disabled={isUnsubmittable || isProcessing}>ログイン</Button>
      </div>
      {showsPasswordResetFormModal && <PasswordResetFormModal isOpen onClickClose={togglePasswordResetFormModal} />}
    </Form>
  );
};
