const { pickBy, isEmpty, omit, } = require('lodash');
const { isEmail, } = require('validator');

const { screens, pkgScreens, } = require('../config');
const { roles, } = require('./user');

const { keys, entries } = Object;

const fields = ({ company, currentRole, } = {}) => {
  return {
    role: {
      label: '権限',
      type: 'select',
      options: entries(roles).map(([k, v]) => ({ label: v, value: k, })),
      validations: {
        required: v => !isEmpty(v),
      },
      readOnly: _ => currentRole === 'owner',
    },
  };
};

const addingFields = (...args) => {
  return {
    email: {
      type: 'string',
      label: 'メールアドレス',
      validations: {
        required: v => !isEmpty(v),
        format: v => !v || isEmail(v),
      },
    },
    ...fields(...args),
    role: {
      ...fields(...args).role,
      options: fields(...args).role.options.filter(_ => _.value !== 'owner'),
    },
  };
};

exports.fields = fields;
exports.addingFields = addingFields;
