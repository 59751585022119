import { initializeApp } from 'firebase/app';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';
import { getFirestore, writeBatch, } from 'firebase/firestore';
import { chunk, } from 'lodash';

import env from './env';

const config =  {
  apiKey: env('FIREBASE_API_KEY'),
  authDomain: env('FIREBASE_AUTH_DOMAIN'),
  databaseURL: env('FIREBASE_DATABASE_URL'),
  projectId: env('FIREBASE_PROJECT_ID'),
  storageBucket: env('FIREBASE_STORAGE_BUCKET'),
  messagingSenderId: env('FIREBASE_MESSAGING_SENDER_ID'),
};

const app = initializeApp(config);

export const db = getFirestore(app);
export const functions = getFunctions(app, 'asia-northeast1');

if(env('CLOUD_FUNCTIONS_EMULATOR') === 'true') {
  connectFunctionsEmulator(functions, 'localhost', 5000);
}

export default app;

export const batch = async (data, f) => {
  return await chunk(data, 500).reduce(async (x, data, i) => {
    const prevs = await x;

    const batch = writeBatch(db);
    const refs = data.map((_, i2) => f(batch, _, i * 500 + i2));
    await batch.commit();
    return [...prevs, ...refs];
  }, Promise.resolve([]));
};
