const { isEmpty, } = require('lodash');
const { addMonths, format: formatDate, parseISO, } = require('date-fns');

const { invertedFreeeFieldMapping, noSelect, } = require('../config');

const { entries } = Object;

const currentYear = (new Date()).getFullYear();

const detailRowFilters = {
  accountItem: (rows, xs) => rows.filter(_ => xs.includes(_[invertedFreeeFieldMapping.accountItemId]?.toString())),
  tax: (rows, xs) => rows.filter(_ => xs.includes(_[invertedFreeeFieldMapping.taxId]?.toString())),
  partner: (rows, xs) => rows.filter(_ => xs.includes(_.deal[invertedFreeeFieldMapping.partnerId]?.toString())),
  section: (rows, xs) => rows.filter(_ => xs.includes(_[invertedFreeeFieldMapping.sectionId]?.toString())),
  item: (rows, xs) => rows.filter(_ => xs.includes(_[invertedFreeeFieldMapping.itemId]?.toString())),
  tag: (rows, xs) => rows.filter(_ => _[invertedFreeeFieldMapping.tagIds]?.some(_ => xs.includes(_.toString()))),
  segment1: (rows, xs) => rows.filter(_ => xs.includes(_[invertedFreeeFieldMapping.segment1Id]?.toString())),
  segment2: (rows, xs) => rows.filter(_ => xs.includes(_[invertedFreeeFieldMapping.segment2Id]?.toString())),
  segment3: (rows, xs) => rows.filter(_ => xs.includes(_[invertedFreeeFieldMapping.segment3Id]?.toString())),
};

const detailRowFilterOptionFilters = {
  accountItem: (xs, { dealSetting }) => xs.filter(_ => dealSetting?.defermentAccountItemIds.includes(_.id)),
  tax: (xs) => xs.filter(_ => _.available),
  partner: _ => [noSelect, ..._],
  section: _ => [noSelect, ..._],
  item: _ => [noSelect, ..._],
  tag: _ => _,
  segment1: _ => [noSelect, ..._],
  segment2: _ => [noSelect, ..._],
  segment3: _ => [noSelect, ..._],
};

const renewDetailRowFilterOptionFilters = {
  accountItem: _ => _,
  tax: (xs) => xs.filter(_ => _.available),
  partner: _ => [noSelect, ..._],
  section: _ => [noSelect, ..._],
  item: _ => [noSelect, ..._],
  tag: _ => _,
  segment1: _ => [noSelect, ..._],
  segment2: _ => [noSelect, ..._],
  segment3: _ => [noSelect, ..._],
};

const fractionPolicies = {
  start: {
    label: '最初に寄せる',
  },
  end: {
    label: '最後に寄せる',
  },
};

const startTypes = {
  after: {
    label: '発生月から何ヶ月後',
  },
  yearMonth: {
    label: '年月で指定',
  },
};

const computeAmountWithTax = ({ amount, vat }, fiscalYear, withoutTax = false) => {
  return withoutTax ? amount : fiscalYear.tax_account_method.toString() === '0' ? amount : amount - vat;
};

const batchFields = ({ company, accountItems, taxes, sections, items, tags, segment1s, segment2s, segment3s, }) => {
  return {
    months: {
      label: '按分期間(月)',
      type: 'integer',
      validations: {
        required: v => v != null,
        greaterThan0: v => v == null || v >= 1,
        lessThanOrEaualToX: v => v == null || v <= 120,
      },
      initialValue: 12,
      hint: '120まで指定できます',
    },
    startType: {
      label: '按分開始月',
      type: 'select',
      options: entries(startTypes).map(([k, v]) => ({ label: v.label, value: k })),
      validations: {
        required: v => v != null,
      },
      initialValue: 'after',
    },
    startAfter: {
      type: 'integer',
      validations: {
        required: v => v != null,
        greaterThanOrEqualTo0: v => v == null || v >= 0,
        lessThanOrEaualToX: v => v == null || v <= 120,
      },
      initialValue: 0,
      hint: '120まで指定できます',
      hidden: _ => _.startType !== 'after',
    },
    startYearMonth: {
      type: 'date',
      validations: {
        required: v => v != null,
      },
      hidden: _ => _.startType !== 'yearMonth',
      selector: true,
      yearRange: [currentYear - 10, currentYear + 20],
      inputProps: {
        onlyYearMonth: true,
      },
      initialValue: new Date(),
    },
    fractionPolicy: {
      label: '端数処理',
      type: 'select',
      options: entries(fractionPolicies).map(([k, v]) => ({ label: v.label, value: k })),
      validations: {
        required: v => v != null,
      },
      initialValue: 'end',
    },
    accountItemId: {
      label: '勘定科目',
      type: 'select',
      options: accountItems.map(_ => ({ label: _.name, value: _.id })),
      validations: {
        required: v => v != null,
      },
    },
    taxId: {
      label: '税区分',
      type: 'select',
      options: taxes.filter(_ => _.available).map(_ => ({ label: _.name_ja, value: _.id })),
      validations: {
        required: v => v != null,
      },
    },
    sectionId: {
      label: '部門',
      type: 'select',
      options: sections.map(_ => ({ label: _.name, value: _.id })),
      hint: '選択しない場合は取引明細と同様のものが使用されます',
    },
    itemId: {
      label: '品目',
      type: 'select',
      options: items.map(_ => ({ label: _.name, value: _.id })),
      hint: '選択しない場合は取引明細と同様のものが使用されます',
    },
    tagIds: {
      label: 'メモタグ',
      type: 'multiSelect',
      options: tags.map(_ => ({ label: _.name, value: _.id })),
      hint: '選択しない場合は取引明細と同様のものが使用されます',
    },
    ...(
      company.usesSegment1 && {
        segment1Id: {
          label: 'セグメント1',
          type: 'select',
          options: segment1s.map(_ => ({ label: _.name, value: _.id })),
          hint: '選択しない場合は取引明細と同様のものが使用されます',
        },
      }
    ),
    ...(
      company.usesSegment2And3 && {
        segment2Id: {
          label: 'セグメント2',
          type: 'select',
          options: segment2s.map(_ => ({ label: _.name, value: _.id })),
          hint: '選択しない場合は取引明細と同様のものが使用されます',
        },
        segment3Id: {
          label: 'セグメント3',
          type: 'select',
          options: segment3s.map(_ => ({ label: _.name, value: _.id })),
          hint: '選択しない場合は取引明細と同様のものが使用されます',
        },
      }
    ),
    description: {
      label: '備考',
      type: 'string',
      hint: '選択しない場合は取引明細と同様のものが使用されます',
    },
  };
};

const fields = ({ company, deal, detail, leftAmountWithTax, accountItems, taxes, sections, items, tags, segment1s, segment2s, segment3s, }) => {
  const { issue_date } = deal;
  const issueDate = parseISO(issue_date);

  return {
    amount: {
      label: '按分金額(税込)',
      type: 'integer',
      validations: {
        greaterThan0: v => v > 0,
        lessThanOrEaualToX: v => v <= leftAmountWithTax,
      },
      initialValue: leftAmountWithTax,
    },
    months: {
      label: '按分期間(月)',
      type: 'integer',
      validations: {
        required: v => v != null,
        greaterThan0: v => v == null || v >= 1,
        lessThanOrEaualToX: v => v == null || v <= 120,
      },
      initialValue: 12,
      hint: '120まで指定できます',
    },
    startType: {
      label: '按分開始月',
      type: 'select',
      options: entries(startTypes).map(([k, v]) => ({ label: v.label, value: k })),
      validations: {
        required: v => v != null,
      },
      initialValue: 'after',
    },
    startAfter: {
      type: 'integer',
      validations: {
        required: v => v != null,
        greaterThanOrEqualTo0: v => v == null || v >= 0,
        lessThanOrEaualToX: v => v == null || v <= 120,
      },
      initialValue: 0,
      hint: '120まで指定できます',
      hidden: _ => _.startType !== 'after',
    },
    startYearMonth: {
      type: 'date',
      validations: {
        required: v => v != null,
      },
      initialValue: 0,
      hidden: _ => _.startType !== 'yearMonth',
      selector: true,
      yearRange: [currentYear - 10, currentYear + 20],
      inputProps: {
        onlyYearMonth: true,
      },
      initialValue: new Date(),
    },
    fractionPolicy: {
      label: '端数処理',
      type: 'select',
      options: entries(fractionPolicies).map(([k, v]) => ({ label: v.label, value: k })),
      validations: {
        required: v => v != null,
      },
      initialValue: 'end',
    },
    accountItemId: {
      label: '勘定科目',
      type: 'select',
      options: accountItems.map(_ => ({ label: _.name, value: _.id })),
      validations: {
        required: v => v != null,
      },
    },
    taxId: {
      label: '税区分',
      type: 'select',
      options: taxes.filter(_ => _.available).map(_ => ({ label: _.name_ja, value: _.id })),
      validations: {
        required: v => v != null,
      },
    },
    sectionId: {
      label: '部門',
      type: 'select',
      options: sections.map(_ => ({ label: _.name, value: _.id })),
      initialValue: detail.section_id?.toString(),
    },
    itemId: {
      label: '品目',
      type: 'select',
      options: items.map(_ => ({ label: _.name, value: _.id })),
      initialValue: detail.item_id?.toString(),
    },
    tagIds: {
      label: 'メモタグ',
      type: 'multiSelect',
      options: tags.map(_ => ({ label: _.name, value: _.id })),
      initialValue: (detail.tag_ids || []).map(_ => _.toString()),
    },
    ...(
      company.usesSegment1 && {
        segment1Id: {
          label: 'セグメント1',
          type: 'select',
          options: segment1s.map(_ => ({ label: _.name, value: _.id })),
          initialValue: detail.segment_1_tag_id?.toString(),
        },
      }
    ),
    ...(company.usesSegment2And3 && {
        segment2Id: {
          label: 'セグメント2',
          type: 'select',
          options: segment2s.map(_ => ({ label: _.name, value: _.id })),
          initialValue: detail.segment_2_tag_id?.toString(),
        },
        segment3Id: {
          label: 'セグメント3',
          type: 'select',
          options: segment3s.map(_ => ({ label: _.name, value: _.id })),
          initialValue: detail.segment_3_tag_id?.toString(),
        },
      }
    ),
    description: {
      label: '備考',
      type: 'string',
      initialValue: detail.description,
    },
  };
};

exports.batchFields = batchFields;
exports.fields = fields;
exports.detailRowFilters = detailRowFilters;
exports.detailRowFilterOptionFilters = detailRowFilterOptionFilters;
exports.renewDetailRowFilterOptionFilters = renewDetailRowFilterOptionFilters;
exports.computeAmountWithTax = computeAmountWithTax;
