import React, { useEffect, } from 'react';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter, } from 'reactstrap';
import { httpsCallable, } from 'firebase/functions';
import { useHistory, useLocation, } from 'react-router';
import { omit, mapValues, isFunction, } from 'lodash';
import { useToggle } from 'react-use';
import { Elements, useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import retry from 'async-retry';
import qs from 'qs';
import { toast } from 'react-toastify';

import { functions, } from '../../firebase';
import env from '../../env'; import useQueryParams from '../hooks/useQueryParams';
import ProgressButton from '../ProgressButton';
import OverlayLoading from '../OverlayLoading';

const { entries } = Object;
const stripePromise = loadStripe(env('STRIPE_PUBLIC_KEY'));
const syncSetupIntent = httpsCallable(functions, 'syncSetupIntent');
const cancelStripeSetupIntent = httpsCallable(functions, 'cancelStripeSetupIntent');

export default function StripeSubscriptionCompleteModal(props) {
  const { company, } = props;
  const clientSecret = company.stripeSetupIntent?.client_secret;
  const history = useHistory();
  const location = useLocation();
  const queryParams = useQueryParams();
  const onClickCancel = async () => {
    await retry(async () => {
      await cancelStripeSetupIntent({ companyId: company.id, });
    }, { retries: 2 });
  };
  useEffect(() => {
    if(['requires_confirmation'].includes(company?.stripeSetupIntent?.status) && queryParams.setup_intent_client_secret) {
      (async () => {
        await retry(_ => syncSetupIntent({ companyId: company.id, }), { retries: 2 });
        history.replace(location.pathname + '?' + qs.stringify({ ...omit(queryParams, ['setup_intent', 'redirect_status', 'setup_intent_client_secret']), }));
      })();
    }
  }, [company, queryParams.setup_intent_client_secret]);
  const onError = (error) => {
    toast.error(error.message);
  }

  return ['requires_confirmation'].includes(company?.stripeSetupIntent?.status) && (
    <Modal isOpen>
      <ModalHeader>
        お支払い方法の変更
      </ModalHeader>
      <ModalBody>
        {
          clientSecret && (
            <Elements stripe={stripePromise} options={{ clientSecret }}>
              <SetupForm onError={onError} clientSecret={clientSecret} />
            </Elements>
          )
        }
        <div className="d-flex mt-1 justify-content-end">
          <ProgressButton color="link" size="sm" className="text-muted" process={onClickCancel}>
            キャンセル
          </ProgressButton>
        </div>
        <OverlayLoading isOpen={queryParams.setup_intent_client_secret} label="処理中..." />
      </ModalBody>
    </Modal>
  );
};

function SetupForm(props) {
  const stripe = useStripe();
  const elements = useElements();
  const onSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) return;

    const result = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: window.location.href,
      },
    });

    if (result.error) {
      props.onError(result.error);
      console.log(result.error.message);
    }
  };

  return (
    <form onSubmit={onSubmit}>
      <PaymentElement />
      <Button className="mt-3" block color="primary" disabled={!stripe}>
        設定する
      </Button>
    </form>
  );
};
