const { format: formatDate, addMonths, endOfMonth, } = require('date-fns');
const { isArray, isObject, mapValues, range, isFunction, } = require('lodash');
const numeral = require('numeral');

const fieldDisplayValue = (values, key, { type, options: _options, }) => {
  const v = values[key];
  const options = isFunction(_options) ? _options(values) : _options;
  return (({
    select: _ => (options.find(_ => _.value === v) || {}).label,
    multiSelect: _ => (v || []).map(v => (options.find(_ => _.value === v) || {}).label).join(', '),
    date: _ => v && formatDate(v.toDate(), 'yyyy/MM/dd'),
    datetime: _ => v && formatDate(v.toDate(), 'yyyy/MM/dd HH:mm'),
    boolean: _ => ({ true: 'はい', false: 'いいえ' })[v || false],
  })[type] || (_ => v))();
};

const yearMonthDate = _ => new Date(parseInt(_.slice(0, 4), 10), parseInt(_.slice(4, 6), 10) - 1, 1);
const prevYearMonth = _ => formatDate(addMonths(yearMonthDate(_), -1), 'yyyyMM');
const floatFormat = (value) => {
  return numeral(value).format('0,0.00[0000]');
};
const integerFormat = (value) => {
  return numeral(value).format('0,0');
};
const integerChangeFormat = (value) => {
  return numeral(value).format('+0,0');
};

const periodOfFiscalYear = (fiscalYear) => {
  return fiscalYear && fiscalYear.start_date.replace(/-/g, '').slice(0, 6);
};

const yearMonthsOfPeriod = (period, fiscalYears = []) => {
  const fiscalYear = fiscalYearOfPeriod(period, fiscalYears);
  return range(0, 12).map(_ => endOfMonth(addMonths(new Date(fiscalYear?.start_date), _)));
};

const fiscalYearOfPeriod = (period, fiscalYears = []) => {
  return fiscalYears.find(_ => periodOfFiscalYear(_) === period);
};

const numerize = (v) => {
  return v == null ? (
    null
  ) : isArray(v) ? (
    v.map(numerize)
  ) : isObject(v) ? (
    mapValues(v, numerize)
  ) : numeral(v).value();
};

exports.fieldDisplayValue = fieldDisplayValue;
exports.yearMonthDate = yearMonthDate;
exports.prevYearMonth = prevYearMonth;
exports.floatFormat = floatFormat;
exports.integerFormat = integerFormat;
exports.integerChangeFormat = integerChangeFormat;
exports.periodOfFiscalYear = periodOfFiscalYear;
exports.fiscalYearOfPeriod = fiscalYearOfPeriod;
exports.yearMonthsOfPeriod = yearMonthsOfPeriod;
exports.numerize = numerize;
