const { groupBy, keyBy, findKey, sortBy, isEmpty, } = require('lodash');

const { keys, entries } = Object;
const types = {
  renewPlus: {
    label: '+更新プラス',
  },
};
const generateRows = (plans, stripeProducts, stripePrices) => {
  const stripeProductsById = keyBy(stripeProducts, 'id');
  const stripePricesById = keyBy(stripePrices, 'id');
  const rows = plans.map((plan) => {
    const { monthStripeProductId, yearStripeProductId, } = plan;
    const monthStripeProduct = stripeProductsById[monthStripeProductId];
    const monthStripePrice = stripePricesById[monthStripeProduct?.default_price];
    const yearStripeProduct = stripeProductsById[yearStripeProductId];
    const yearStripePrice = stripePricesById[yearStripeProduct?.default_price];
    return {
      ...plan,
      plan,
      monthStripeProduct,
      monthStripePrice,
      yearStripeProduct,
      yearStripePrice,
    };
  });
  const sortedRows = sortBy(rows, [_ => keys(types).indexOf(_.type), _ => !_.isFree, _ => _.monthStripePrice?.unit_amount]);
  return sortedRows;
};

const fields = ({ stripeProducts, stripePrices, }) => {
  const stripePricesById = keyBy(stripePrices, 'id');
  const { month: monthStripeProducts, year: yearStripeProducts } = groupBy(stripeProducts, _ => stripePricesById[_.default_price]?.recurring?.interval);
  return {
    type: {
      label: '種別',
      type: 'select',
      options: entries(types).map(([k, v]) => ({ label: v.label, value: k, })),
      validations: {
        required: v => !isEmpty(v),
      },
    },
    name: {
      label: '名称',
      type: 'string',
      validations: {
        required: _ => !isEmpty(_),
      },
    },
    isFree: {
      label: 'フリー',
      type: 'boolean',
      initialValue: false,
    },
    isForSupporter: {
      label: 'サポーター向け',
      type: 'boolean',
      initialValue: false,
    },
    monthStripeProductId: {
      label: '月次',
      type: 'select',
      options: monthStripeProducts?.map(_ => ({ label: _.name, value: _.id, })),
      validations: {
        required: v => !isEmpty(v),
      },
      hidden: _ => _.isFree,
    },
    yearStripeProductId: {
      label: '年次',
      type: 'select',
      options: yearStripeProducts?.map(_ => ({ label: _.name, value: _.id, })),
      validations: {
        required: v => !isEmpty(v),
      },
      hidden: _ => _.isFree,
    },
    value1: {
      label: '値1',
      type: 'integer',
    },
  };
};

exports.fields = fields;
exports.types = types;
exports.generateRows = generateRows;
