import React, { useEffect, useState, } from 'react'
import { collection, doc, getDoc, getDocs, query, where, orderBy, limit, addDoc, updateDoc, setDoc, } from 'firebase/firestore';
import { Button, Form, Input } from 'reactstrap';
import Select from 'react-select';

import { fields } from '../shared/models/defermentTemplate';
import { onStateChanged, } from '../shared/models/deal';
import useCollectionSubscription from './hooks/useCollectionSubscription';
import useCompany from './hooks/useCompany';
import ModelFormModal from './modals/ModelFormModal';
import AddButton from './AddButton';
import EditButton from './EditButton';
import DeleteButton from './DeleteButton';

export default function DefermentTemplate (props) {
  const { masters, ...extraProps } = props;
  const { accountItemsById } = masters;
  const company = useCompany();
  const [defermentTemplateId, setDefermentTemplateId] = useState(null);
  const defermentTemplates = useCollectionSubscription(query(collection(company.ref, 'defermentTemplates'), orderBy('createdAt')));
  const defermentTemplateOptions = defermentTemplates.map(_ => ({ label: _.name, value: _.id }));

  return (
    <div {...extraProps}>
      <div className="mb-3 d-flex justify-content-end gap-1">
        <AddButton
          itemRef={doc(collection(company.ref, 'defermentTemplates'))}
          FormModal={ModelFormModal}
          formProps={{
            title: '按分テンプレート 追加',
            fields: fields({ company, ...masters, }),
            onStateChanged: onStateChanged.bind(null, accountItemsById),
          }}
        />
      </div>
      <div>
        <table className="table sticky-table">
          <thead className="thead-light text-center">
            <tr>
              <th style={{ minWidth: 200 }}>名称</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {
              defermentTemplates.map((defermentTemplate) => {
                const { id, ref, name, } = defermentTemplate;

                return (
                  <tr key={id}>
                    <td>
                      {name}
                    </td>
                    <td className="text-nowrap text-right">
                      <EditButton itemRef={ref} FormModal={ModelFormModal} formProps={{ title: '按分テンプレート 編集', fields: fields({ company, ...masters }), }} />
                      <DeleteButton item={defermentTemplate} itemRef={ref} className="ml-2" />
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  );
};
