import React, { Fragment, } from 'react';
import { httpsCallable, } from 'firebase/functions';
import { Button, Input, } from 'reactstrap';
import { collection, doc, getDoc, getDocs, query, where, orderBy, limit, addDoc, updateDoc, setDoc, } from 'firebase/firestore';
import { toast } from 'react-toastify';
import { uniq, pickBy, pick, difference, last, range, groupBy, orderBy as _orderBy, sumBy, merge, uniqBy, keyBy, isEmpty, mapValues } from 'lodash';
import numeral from 'numeral';
import { format as formatDate, getYear, parseISO, isEqual as isEqualDate, startOfMonth, endOfMonth, addYears, addMonths, addHours, addMinutes, } from 'date-fns';
import { useToggle, useList, } from 'react-use';
import { Link, } from 'react-router-dom';
import retry from 'async-retry';

import { db, functions, batch, } from '../../firebase';
import { types, generateRows, } from '../../shared/models/plan';
import plansDescriptionImage from '../../images/renew_plus_plans_description.png';
import CompanyPage from '../hocs/CompanyPage';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import ModelFormModal from '../modals/ModelFormModal';
import StripeSubscriptionCompleteModal from '../modals/StripeSubscriptionCompleteModal';
import ProgressButton from '../ProgressButton';
import ModalButton from '../ModalButton';
import EditButton from '../EditButton';

const { floor } = Math;
const { entries } = Object;
const applyPlan = httpsCallable(functions, 'applyPlan');

export default CompanyPage(function CompanyRenewPlusPlans (props) {
  const { user, company, period, } = props;
  const stripeProducts = useCollectionSubscription(query(collection(db, 'stripeProducts')));
  const stripePrices = useCollectionSubscription(query(collection(db, 'stripePrices')));
  const [incompleteSubscription] = useCollectionSubscription(query(collection(company.ref, 'stripeSubscriptions'), where('type', '==', 'renewPlus'), where('source.status', '==', 'incomplete'), where('source.canceled_at', '==', null)));
  const [activeSubscription] = useCollectionSubscription(query(collection(company.ref, 'stripeSubscriptions'), where('type', '==', 'renewPlus'), where('source.status', '==', 'active'), where('source.canceled_at', '==', null)));
  const stripeProductsById = keyBy(stripeProducts, 'id');
  const stripePricesById = keyBy(stripePrices, 'id');
  const plans = useCollectionSubscription(query(collection(db, 'plans'), where('type', '==', 'renewPlus')));
  const currentPlan = plans.find(_ => _.id === activeSubscription?.planId) || plans.find(_ => _.isFree);
  const displayPlans = user.isSupporter ? plans : plans.filter(_ => !_.isForSupporter);
  const planRows = generateRows(displayPlans, stripeProducts, stripePrices);

  return (
    <div className="company-renew-plus-plans container">
      <div className="p-4 bg-white my-4">
        <div className="d-flex justify-content-center mb-1">
          <h4>+更新プラス プラン</h4>
        </div>
        <div className="d-flex justify-content-end mb-5">
          <a className="font-weight-bold" href="https://docs.google.com/forms/d/e/1FAIpQLSd0sIxHMrMfF0KFsYE31lAcZ9Hlqpis_kOhMQItpKpjgmgHHg/viewform" target="_blank">
            <span className="fas fa-angles-right mr-1" />
            会計事務所向けプランをご検討の方はこちら
          </a>
        </div>
        <div className="d-flex justify-content-start mb-1">
          <div>
            <div className="text-muted small">
              ※ 表示価格はすべて税抜きです
            </div>
            <div className="text-muted small">
              ※ ユーザー数の上限はすべてのプランで5人です。超える場合はお問い合わせください。
            </div>
          </div>
        </div>
        <div className="d-flex flex-wrap gap-2 justify-content-around">
          {
            planRows.map((planRow) => {
              const { id, name, isFree, monthStripePrice, monthStripeProductId, yearStripePrice, yearStripeProductId, value1, } = planRow;
              const onClickApplyPlan = async (interval) => {
                const message = `${name}${isFree ? '' : `(${({ month: '月払い', year: '年払い' })[interval]})`}を選択します。よろしいですか？`;
                if(!window.confirm(message)) return;

                await retry(async () => {
                  await applyPlan({ companyId: company.id, type: 'renewPlus', planId: id, interval, });
                }, { retries: 2 });
              };
              return (
                <div key={id}>
                  <div className="border rounded p-2 p-sm-3" style={{ width: 200 }}>
                    <div className="text-center">
                      <h4 className="font-weight-bold" style={{ fontSize: 20 }}>{name}</h4>
                    </div>
                    <hr className="my-3" />
                    <div className="mt-3 d-flex flex-column gap-3 align-items-center text-center">
                      <div style={{ fontSize: 15 }}>
                        月の取引発生数の上限
                      </div>
                      <div>
                        <span className="font-weight-bold" style={{ fontSize: 25, lineHeight: 0, }}>{numeral(value1).format()}</span>件
                      </div>
                    </div>
                    <hr className="my-3" />
                    <div className="mt-3 d-flex flex-column justify-content-around">
                      {
                        isFree ? (
                          <div className="d-flex flex-column align-items-stretch gap-2">
                            <div className="d-flex flex-column align-items-stretch gap-2">
                              <div className="text-center" style={{ fontSize: 15 }}>
                                30日間は制限なし
                              </div>
                              {
                                currentPlan?.id === id ? (
                                  <Button block outline color="primary" disabled>
                                    現在ご利用中
                                  </Button>
                                ) : (
                                  <div>
                                    <ProgressButton block color="primary" process={onClickApplyPlan.bind(null)}>
                                      利用する
                                    </ProgressButton>
                                  </div>
                                )
                              }
                            </div>
                            <hr className="my-2 w-100 invisible" />
                            <div className="d-flex flex-column align-items-stretch gap-2 invisible">
                              <div className="text-center" style={{ fontSize: 15 }}>
                                dummy
                              </div>
                              <Button>
                                dummy
                              </Button>
                            </div>
                          </div>
                        ) : (
                          <div className="d-flex flex-column align-items-stretch gap-2">
                            <div className="d-flex flex-column align-items-stretch gap-2">
                              <div className="d-flex justify-content-between" style={{ fontSize: 15 }}>
                                <div>月払い</div>
                                <div>{numeral(monthStripePrice?.unit_amount).format()}円/月</div>
                              </div>
                              {
                                currentPlan?.id === id && activeSubscription?.interval === 'month' ? (
                                  <Button block outline color="primary" disabled>
                                    現在ご利用中
                                  </Button>
                                ) : (
                                  <ProgressButton block color="primary" process={onClickApplyPlan.bind(null, 'month')}>
                                    利用する
                                  </ProgressButton>
                                )
                              }
                            </div>
                            <hr className="my-2 w-100" />
                            <div className="d-flex flex-column align-items-stretch gap-2">
                              <div className="d-flex justify-content-between" style={{ fontSize: 15 }}>
                                <div>年払い</div>
                                <div>{numeral(yearStripePrice?.unit_amount).format()}円/年</div>
                              </div>
                              {
                                currentPlan?.id === id && activeSubscription?.interval === 'year' ? (
                                  <div className="text-muted text-center">
                                    現在ご利用中
                                  </div>
                                ) : (
                                  <ProgressButton color="primary" process={onClickApplyPlan.bind(null, 'year')}>
                                    利用する
                                  </ProgressButton>
                                )
                              }
                            </div>
                          </div>
                        )
                      }
                    </div>
                  </div>
                </div>
              );
            })
          }
        </div>
        <div className="mt-3">
          <img src={plansDescriptionImage} style={{ }} />
        </div>
      </div>
      <StripeSubscriptionCompleteModal type="renewPlus" {...{ company, incompleteSubscription, }} />
    </div>
  );
});
