// TODO: 整理
// import { getAuth } from 'firebase/auth';
import qs from 'qs';
import { collection, doc, getDoc, query, where, orderBy, limit, addDoc, updateDoc, setDoc, } from 'firebase/firestore';
import { max as maxDate, parse as parseDate, format as formatDate, startOfMonth, endOfMonth, isWithinInterval } from 'date-fns';
import { get, flattenDeep, uniqBy, isEmpty, zipObject, max, findIndex, groupBy, last, sortBy, keyBy, omit, uniq, pick, } from 'lodash';
import { TextDecoder } from 'text-encoding';
import { parse as parseCsv } from 'papaparse';

import env from './env';
import { periodOfFiscalYear, fiscalYearOfPeriod, } from './shared/util';

const { entries, values } = Object;

export const downloadPdf = (filename) => {
  return fetchPdf()
    .then(async (blob) => {
      const newBlob = new Blob([blob], { type: 'application/pdf' })
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        return window.navigator.msSaveOrOpenBlob(newBlob);
      }
      const data = window.URL.createObjectURL(newBlob);
      const link = document.createElement('a');
      link.href = data;
      link.download = filename;
      link.click();
      await setTimeout(() => {}, 100);
      window.URL.revokeObjectURL(data);
      return true;
    });
};

export const fetchPdf = () => {
  const url = env('SG_HTML_2_PDF_ENDPOINT');
  const html = document.documentElement.outerHTML
    .replace(/<script.*?>.*?<\/script>/gi, '')
    .replace(/href="\/static/gi, `href="${env('HOSTING_HOST')}/static`);
  const body = JSON.stringify({ html });
  const headers = { 'Content-Type': 'application/json' };
  return fetch(url, { method: 'POST', body, headers }).then(_ => _.blob());
};

export function readFile(file, type = 'readAsText') {
  const reader = new FileReader();
  reader[type](file);
  return new Promise((resolve) => {
    reader.addEventListener('load', _ => resolve(_.target.result));
  });
};

export function fullPathWithParams(params, { pathname, search }) {
  const currentParams = qs.parse(decodeURI(search.slice(1)));
  const newParams = {
    ...currentParams,
    ...params
  };
  const newSearch = qs.stringify(newParams);
  return `${pathname}${newSearch ? `?${newSearch}` : ''}`;
};

export function closingDate({ fiscalYears }, period) {
  const fiscalYear = fiscalYearOfPeriod(period, fiscalYears);
  return endOfMonth((fiscalYear || {}).end_date);
};


export function isDebitAccountItem({ acccount_category }) {
};

export function pickSearch(search, keys) {
  const params = qs.parse(search.slice(1));
  return `?${qs.stringify(pick(params, keys))}`;
};

export function autoLink(text, { linkAttr = {} } = {}) {
  return text.replace(/((http|https|ftp):\/\/[\w?=&.\/-;#~%-]+(?![\w\s?&.\/;#~%"=-]*>))/g, `<a href="$1" ${entries(linkAttr).map(([k, v]) => `${k}="${v}"`).join(' ')}>$1</a> `);
};

export function nl2br(text) {
  return text.replace(/(\n|\r\n)/g, '<br />');
};

export function periodByDate(date, company) {
  return periodOfFiscalYear(fiscalYearOfDate(date, company));
};

export function fiscalYearOfDate(date, { fiscalYears = [] }) {
  return fiscalYears.find(_ => isWithinInterval(date, { start: startOfMonth(parseDate(_.start_date, 'yyyy-MM-dd', new Date())), end: endOfMonth(parseDate(_.end_date, 'yyyy-MM-dd', new Date())) }));
};

export function existsInFiscalYears(date, { fiscalYears = [] }) {
  return fiscalYearOfDate(date, { fiscalYears }) != null;
};

export function startOfMonthByFiscalYears(date, { fiscalYears = [] } = {}) {
  const fiscalYear = fiscalYearOfDate(date, { fiscalYears });
  return maxDate([startOfMonth(date), parseDate((fiscalYear || {}).start_date, 'yyyy-MM-dd', new Date())]);
};

export async function trialCsvFileToData(type, dimensionName, file) {
};

export const formatTrialCommentAbout = ({ documentType = 'bs', date, itemName, subItemName }) => {
};

const amountTypeLabels = {
  累計値: 'closing',
  発生値: 'occurrence',
};

const accountItemBalanceOfTrials = (amountType, item, { closingDate, dimension, balancesByAccountItemName = {}, balancesByAccountCategoryName = {}, balancesByItemKey = {}, customAccountItems = [] }) => {
  const { isCustom, isSubRow, itemName, itemKey, subItemName } = item;

  const balanceItem = balancesByItemKey[itemKey] || {};
  return isSubRow ?
    getSubBalance(amountType, dimension, subItemName, balanceItem)
  : getAmountsByType[amountType](balanceItem);
};

const getClosingBalance = _ => _.closing_balance || 0;
const getOpeningBalance = _ => _.opening_balance || 0;
const getOccurrence = _ => getClosingBalance(_) - getOpeningBalance(_);
const getAmountsByType = {
  opening: getOpeningBalance,
  closing: getClosingBalance,
  occurrence: getOccurrence,
};

const getSubBalance = (amountType, dimension, subItemName, balanceItem) => {
  const { [dimension]: subItems = [] } = balanceItem;
  return getAmountsByType[amountType](subItems.find(_ => _.name === subItemName) || {}) || 0;
};

export const accountItemClosingBalanceOfTrials = (item, monthItem) => {
  return accountItemBalanceOfTrials('closing', item, monthItem);
};

export const accountItemOpeningBalanceOfTrials = (item, monthItem) => {
  return accountItemBalanceOfTrials('opening', item, monthItem);
};

export const accountItemOccurrenceOfTrials = (item, monthItem) => {
  return accountItemBalanceOfTrials('occurrence', item, monthItem);
};

export async function log(company, modelName, operationType, user, payload) {
  await addDoc(collection(company.ref, 'logs'), {
    modelName,
    operationType,
    payload,
    createdBy: pick(user, ['id', 'displayName', 'email', 'type', 'admin']),
    createdAt: new Date(),
  });
}

export { periodOfFiscalYear, fiscalYearOfPeriod, };
