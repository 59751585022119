import React from 'react';
import classnames from 'classnames';
import { useHistory, useLocation, } from 'react-router';
import qs from 'qs';

import useQueryParams from './hooks/useQueryParams';

export default function ThWithOrder (props) {
  const { sortKey, children, ...extraProps } = props;
  const queryParams = useQueryParams();
  const history = useHistory();
  const location = useLocation();
  const onClick = () => {
    history.replace(location.pathname + '?' + qs.stringify({ ...queryParams, sortKey, sortDirection: queryParams.sortKey === sortKey && queryParams.sortDirection === 'asc' ? 'desc' : 'asc', }));
  };

  return (
    <th className="position-relative" {...extraProps}>
      <span className="text-primary cursor-pointer" onClick={onClick}>{children}</span>
      {
        queryParams.sortKey === sortKey && (
          <div className="position-absolute" style={{ top: 4, right: 5 }}>
            <span className={classnames('fas', { 'fa-angle-up': queryParams.sortDirection === 'asc', 'fa-angle-down': queryParams.sortDirection === 'desc' })} />
          </div>
        )
      }
    </th>
  );
}
