import React, { useState } from 'react';
import { Button, Form, Input } from 'reactstrap';
import { collection, doc, getDoc, query, where, orderBy, limit, addDoc, updateDoc, setDoc, } from 'firebase/firestore';
import { httpsCallable, } from 'firebase/functions';
import { toast } from 'react-toastify';
import { useToggle } from 'react-use';
import { mapValues, pick, last, sortBy, omit } from 'lodash';
import Select from 'react-select';
import { useAsync } from 'react-use';
import numeral from 'numeral';
import classnames from 'classnames';
import retry from 'async-retry';

import firebase, { functions, db, } from '../../firebase';
import SettingsPage from '../hocs/SettingsPage';
import { fieldDisplayValue } from '../../shared/util';
import { generalSettingsFields } from '../../shared/models/company';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import { log } from '../../utils';
import ModelFormModal from '../modals/ModelFormModal';
import StripePaymentMethodSetupModal from '../modals/StripePaymentMethodSetupModal';
import EditButton from '../EditButton';
import ProgressButton from '../ProgressButton';
import StripeCardDisplay from '../StripeCardDisplay';

const { entries, keys, } = Object;
const createStripeSetupIntent = httpsCallable(functions, 'createStripeSetupIntent');

export default SettingsPage(function CompanyPayment (props) {
  const { user, company, } = props;
  const onClickEditCard = async () => {
    await retry(_ => createStripeSetupIntent({ companyId: company.id }));
  };

  return (
    <div className="company-members container">
      <div className="d-flex justify-content-center">
        <div className="border rounded p-2" style={{ width: 300 }}>
          <div className="text-center">
            <h4 className="font-weight-bold">お支払い方法</h4>
          </div>
          <hr className="my-3" />
          <div className="text-center" style={{ minHeight: 150 }}>
            {
              company.stripePaymentMethod != null ? (
                <div>
                  {
                    [company.stripePaymentMethod].filter(_ => _).map((stripePaymentMethod) => {
                      return <StripeCardDisplay key={stripePaymentMethod.id} paymentMethod={stripePaymentMethod} />
                    })
                  }
                  <ProgressButton block color="primary" process={onClickEditCard}>
                    変更する
                  </ProgressButton>
                </div>
              ) : (
                <div className="text-muted">
                  お支払い方法はまだ設定されていません
                </div>
              )
            }
          </div>
        </div>
      </div>
      <StripePaymentMethodSetupModal {...{ company }} />
    </div>
  );
});
