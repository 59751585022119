import React, { useState } from 'react';
import numeral from 'numeral';
import classnames from 'classnames';

export default function StripeCardDisplay (props) {
  const { paymentMethod } = props;
  const { card, } = paymentMethod;

  return (
    <div className={classnames('credit-card', card.brand)}>
      <div className="credit-card-last4">
        {card.last4}
      </div>
      <div className="credit-card-expiry">
        {numeral(card.exp_month).format('00')}/{numeral(card.exp_year).format('00')}
      </div>
    </div>
  );
}
