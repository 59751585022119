import { useState, useEffect } from 'react';
import { onSnapshot, } from 'firebase/firestore';

export default function useDocumentSubscription (ref, dependencies = [], { onError = e => { throw e }, } = {}) {
  const [item, setItem] = useState();
  useEffect(() => {
    if(!ref) return setItem(undefined);

    const unsubscribe = onSnapshot(ref, (doc) => {
      if(doc.exists()) {
        setItem({ id: doc.id, ref, ...doc.data() });
      } else {
        setItem(null);
      }
    }, e => console.error(ref, e) || onError(e));
    return unsubscribe;
  }, dependencies);
  return item;
};
