import React from 'react';
import { Link } from 'react-router-dom';
import { httpsCallable, } from 'firebase/functions';
import { query, where, orderBy, collection, doc } from 'firebase/firestore';
import { format as formatDate } from 'date-fns';
import numeral from 'numeral';
import { sortBy, keyBy } from 'lodash';
import retry from 'async-retry';
import { toast } from 'react-toastify';

import AdminPage from '../hocs/AdminPage';
import firebase, { db, functions } from '../../firebase';
import { types, fields, generateRows, } from '../../shared/models/plan';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import ModelFormModal from '../modals/ModelFormModal';
import AddButton from '../AddButton';
import ProgressButton from '../ProgressButton';
import EditButton from '../EditButton';

const { keys } = Object;
const syncStripeProducts = httpsCallable(functions, 'syncStripeProducts');

export default AdminPage(function AdminPlans(props) {
  const stripeProducts = useCollectionSubscription(query(collection(db, 'stripeProducts')));
  const stripePrices = useCollectionSubscription(query(collection(db, 'stripePrices')));
  const plans = useCollectionSubscription(query(collection(db, 'plans')));
  const planRows = generateRows(plans, stripeProducts, stripePrices);
  const onClickSyncStripeProducts = async () => {
    try {
      await retry(syncStripeProducts, { retries: 2 });
      toast.success('取り込みました');
    } catch(e) {
      toast.error('失敗しました');
    }
  };

  return (
    <div className="admin-plans">
      <div className="container-fluid py-5">
        <div className="d-flex justify-content-center mb-2">
          <h3>プラン管理</h3>
        </div>
        <div className="mb-3 d-flex justify-content-end gap-1">
          <ProgressButton icon={<span className="fas fa-sync" />} process={onClickSyncStripeProducts}>
            Stripe商品を同期
          </ProgressButton>
          <AddButton itemRef={doc(collection(db, 'plans'))} FormModal={ModelFormModal} formProps={{ title: 'プラン 追加', fields: fields({ stripeProducts, stripePrices }), }} />
        </div>
        <table className="table mt-5 table-bordered">
          <thead className="thead-light text-nowrap">
            <tr>
              <th style={{ minWidth: 150 }}>種別</th>
              <th style={{ minWidth: 250 }}>名称</th>
              <th style={{ minWidth: 180 }}>月次</th>
              <th style={{ minWidth: 180 }}>年次</th>
              <th style={{ minWidth: 150 }}>値1</th>
              <th style={{ minWidth: 70 }}></th>
            </tr>
          </thead>
          <tbody>
            {
              planRows.map(({ id, ref, type, name, isFree, isForSupporter, monthStripeProduct, monthStripePrice, yearStripeProduct, yearStripePrice, value1, createdAt, }) => {
                return (
                  <tr key={id} id={id}>
                    <td>
                      {types[type]?.label}
                    </td>
                    <td>
                      {name}
                      {isFree && <span className="badge badge-info">フリー</span>}
                      {isForSupporter && <span className="badge badge-info">サポーター向け</span>}
                    </td>
                    <td>
                      {
                        !isFree && <div>
                          <div>{monthStripeProduct?.name}</div>
                          <div>{numeral(monthStripePrice?.unit_amount).format()}</div>
                        </div>
                      }
                    </td>
                    <td>
                      {
                        !isFree && <div>
                          <div>{yearStripeProduct?.name}</div>
                          <div>{numeral(yearStripePrice?.unit_amount).format()}</div>
                        </div>
                      }
                    </td>
                    <td className="text-right">
                      {value1}
                    </td>
                    <td>
                      <div className="text-nowrap d-flex justify-content-end gap-1">
                        <EditButton itemRef={ref} FormModal={ModelFormModal} formProps={{ title: 'プラン 編集', fields: fields({ stripeProducts, stripePrices, }), }} />
                      </div>
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  );
});

