const { isEmpty, } = require('lodash');

const { entries } = Object;

const dealSettingFields = ({ accountItems = [], } = {}) => {
    return {
      defermentAccountItemIds: {
        label: '勘定科目',
        type: 'multiSelect',
        options: accountItems.map(_ => ({ label: _.name, value: _.id.toString(), })),
      },
    };
};

exports.dealSettingFields = dealSettingFields;
