import React, { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';
import { pickBy, findKey, range, inRange, get, pick, last, sortBy, } from 'lodash';
import { collection, doc } from 'firebase/firestore';
import { useHistory, useParams, useLocation, } from 'react-router';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';
import { format as formatDate, endOfMonth, } from 'date-fns';
import qs from 'qs';
import { useAsync } from 'react-use';

import { periodOfFiscalYear, fiscalYearOfPeriod, fullPathWithParams } from '../../utils';
import firebase, { functions, db, } from '../../firebase';
import { screens, } from '../../shared/config';
import { roles, } from '../../shared/models/user';
import HeaderNav from '../HeaderNav';
import CompanyContext from '../contexts/company';
import CompanySyncButton from '../CompanySyncButton';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useDocumentsFetch from '../hooks/useDocumentsFetch';
import useQueryParams from '../hooks/useQueryParams';

const auth = getAuth(firebase);
const companiesRef = collection(db, 'companies');
const headerLinks = pickBy(screens, 'showsInHeader');
const { entries, keys, } = Object;

export default function CompanyPageHOC(WrappedComponent) {
  return function CompanyPage (props) {
    const { user, match, } = props;
    const location = useLocation();
    const history = useHistory();
    const { companyId } = useParams();
    const queryParams = useQueryParams();
    const company = useDocumentSubscription(doc(companiesRef, companyId), [companyId]);
    const { role, enabledScreens, enabledRelatedCompanyIds, } = company?.users?.[user.id] || {};
    const headerLinksForUser = (user?.admin || ['owner', 'admin'].includes(role)) ? headerLinks : pickBy(headerLinks, (v, k) => enabledScreens?.includes(k));
    const pathName = get(match.path.match(/:companyId\/([^/]+)?/), 1) || '';
    const currentScreen = findKey(screens, ({ pathNames = [] }, screen) => {
      return [...pathNames, screen].includes(pathName);
    });
    const fiscalYears = get(company, 'fiscalYears', []);

    return (
      <div className="company-page h-100">
        {
          company != null && (
            <CompanyContext.Provider value={company}>
              <div className="h-100 d-flex flex-column">
                <HeaderNav {...props} {...{ company, }} links={headerLinksForUser} />
                <div className="flex-grow-1">
                  {
                    (company.fiscalYears != null || match.path.includes('settings/members')) ? (
                      <WrappedComponent {...props} {...{ company, }} />
                    ) : (
                      <div className="py-5 container text-danger text-center">
                        <CompanySyncButton company={company} />
                      </div>
                    )
                  }
                </div>
              </div>
            </CompanyContext.Provider>
          )
        }
      </div>
    );
  };
};
