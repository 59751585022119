import React, { Fragment, useState, useEffect, } from 'react';
import { Button, Input, } from 'reactstrap';
import { collection, doc, getDoc, query, where, orderBy, limit, addDoc, updateDoc, setDoc, } from 'firebase/firestore';
import { httpsCallable, } from 'firebase/functions';
import { omit, pick, pickBy, sumBy, difference, uniqBy, keyBy, isEmpty, mapValues } from 'lodash';
import retry from 'async-retry';
import { useToggle, useList, } from 'react-use';
import Select from 'react-select';
import { toast } from 'react-toastify';
import numeral from 'numeral';
import classnames from 'classnames';
import { format as formatDate, parseISO, startOfMonth, endOfMonth, addMonths, addMinutes, } from 'date-fns';

import { functions } from '../../firebase';
import { freeeFieldMapping, } from '../../shared/config';
import { numerize, fiscalYearOfPeriod, } from '../../shared/util';
import { computeAmountWithTax, fields as defermentFields, detailRowFilters, renewDetailRowFilterOptionFilters, } from '../../shared/models/deferment';
import { statuses, } from '../../shared/models/dealRenewsJob';
import { renewUpdateFields, onStateChanged, } from '../../shared/models/deal';
import { companyFreeeMasters, } from '../../shared/models/company';
import ModelFormModal from '../modals/ModelFormModal';
import DefermentTemplateSelector from '../DefermentTemplateSelector';
import ProgressButton from '../ProgressButton';
import ModalButton from '../ModalButton';
import OverlayLoading from '../OverlayLoading';
import DateSelector from '../DateSelector';
import useCompany from '../hooks/useCompany';
import useQueryParams from '../hooks/useQueryParams';

const { floor } = Math;
const { entries } = Object;
const deleteDealRenews = httpsCallable(functions, 'deleteDealRenews');
const addDealRenews = httpsCallable(functions, 'addDealRenews');
const updateDealRenews = httpsCallable(functions, 'updateDealRenews');
const syncDeal = httpsCallable(functions, 'syncDeal');
const dateRangeFilters = {
  start: {
    label: '開始年月',
    filter: (xs, v) => xs.filter(_ => new Date(_.renew.update_date) >= startOfMonth(v)),
  },
  end: {
    label: '終了年月',
    filter: (xs, v) => xs.filter(_ => new Date(_.renew.update_date) <= endOfMonth(v)),
  },
};

export default function DefermentModalContent(props) {
  const { deal, detail, dealRenewsJob, detailRenews, renewsTotalAmount, leftAmount, leftAmountWithTax, fiscalYear, masters, } = props;
  const { accountItems, taxes, sections, items, tags, segment1s, segment2s, segment3s, accountItemsById, taxesById, sectionsById, itemsById, tagsById, segment1sById, segment2sById, segment3sById, } = masters;
  const isWaitingForJob = dealRenewsJob?.status === 'initial' || dealRenewsJob?.status === 'processing' && (dealRenewsJob?.processStartedAt.toDate() >= addMinutes(new Date(), -10));
  const waitingJobRenewIds = (isWaitingForJob && dealRenewsJob?.renews.map(_ => _.id)) || [];
  const renewsById = keyBy(deal.renews, 'id');
  const company = useCompany();
  const masterOptions = entries(companyFreeeMasters(company)).reduce((x, [name, { plural }]) => ({ ...x, [name]: renewDetailRowFilterOptionFilters[name](masters[plural]).map(_ => ({ label: _.name_ja || _.name, value: _.id })), }), {});
  const [filterState, setFilterState] = useState({});
  const [selectedItemIds, { set: setSelectedItemIds, push: selectItem, removeAt: removeSelectedItemIdAt }] = useList([]);
  const unselectItem = _ => removeSelectedItemIdAt(selectedItemIds.indexOf(_));
  const isSelecting = selectedItemIds.length > 0;
  const generateRowGroups = (deal, detail) => {
    const filteredRenews = deal.renews.filter(_ => _.renew_target_id === detail.id);
    return filteredRenews
      .map((renew, i) => {
        const isLast = i + 1 === filteredRenews.length;
        const renewDetailRows = renew.details.map((detail, i) => {
          const accountItem = accountItemsById[detail.account_item_id];
          const tax = taxesById[detail.tax_code];
          const section = sectionsById[detail.section_id];
          const item = itemsById[detail.item_id];
          const tags = (detail.tag_ids || []).map(_ => tagsById[_]);
          const segment1 = segment1sById[detail.segment_1_tag_id];
          const segment2 = segment2sById[detail.segment_2_tag_id];
          const segment3 = segment3sById[detail.segment_3_tag_id];
          return {
            ...detail,
            renew,
            accountItem,
            tax,
            section,
            item,
            tags,
            segment1,
            segment2,
            segment3,
            isLast,
          };
        });
        const filterDetailRows = (renewDetailRows) => {
          let filterRenewDetailRows = renewDetailRows;
          entries(companyFreeeMasters(company)).map(([name]) => {
            if(!isEmpty(filterState[name])) {
              filterRenewDetailRows = detailRowFilters[name](filterRenewDetailRows, filterState[name].map(_ => _ === '0' ? undefined : _));
            }
          });
          entries(dateRangeFilters).map(([name, { filter }]) => {
            if(filterState[name] != null) {
              filterRenewDetailRows = filter(filterRenewDetailRows, filterState[name]);
            }
          });
          return filterRenewDetailRows;
        };
        const filterRenewDetailRows = filterDetailRows(renewDetailRows);
        return {
          ...renew,
          renewDetailRows,
          filterRenewDetailRows,
          isLast,
        };
      });
  };
  const rowGroups = generateRowGroups(deal, detail);
  const adjustmentAmount = Math.min(0, computeAmountWithTax(detail, fiscalYear) - sumBy(rowGroups.flatMap(_ => _.renewDetailRows), _ => computeAmountWithTax(_, fiscalYear) || 0));
  const filteredRowGroups = rowGroups.filter(_ => _.filterRenewDetailRows.length > 0);
  const onClickDelete = async () => {
    if(!window.confirm('選択された+更新を削除します。よろしいですか？')) return;

    try {
      await setDoc(doc(company.ref, 'dealRenewsJobs', [deal.id, detail.id].join('__')), {
        status: 'initial',
        type: 'delete',
        dealId: deal.id,
        dealIssueDate: deal.issue_date,
        detailId: detail.id,
        renews: selectedItemIds.map(_ => ({ id: _, })),
        updatedAt: new Date(),
        initializedAt: new Date(),
      });
      setSelectedItemIds([]);
    } catch(e) {
      console.error(e);
      toast.error('失敗しました');
    }
  };
  const onSubmitBatchAdd = async (values, { onClickClose }) => {
    const { amount, months, startType, startAfter, startYearMonth, fractionPolicy, } = values;
    const startOn = {
      after: endOfMonth(addMonths(parseISO(deal.issue_date), startAfter)),
      yearMonth: endOfMonth(startYearMonth),
    }[startType];
    const newData = Array(months).fill().map((_, i) => {
      return {
        update_date: formatDate(endOfMonth(addMonths(startOn, i)), 'yyyy-MM-dd'),
        renew_target_id: detail.id,
        details: [pickBy({
          amount: floor(amount / months) + (i === ({ start: 0, end: months - 1 })[fractionPolicy] ? amount % months : 0),
          ...mapValues(pick(freeeFieldMapping, ['tax_code', 'account_item_id', 'section_id', 'item_id', 'tag_ids', 'segment_1_tag_id', 'segment_2_tag_id', 'segment_3_tag_id', 'description']), (v, k) => k === 'description' ? values[v] : numerize(values[v])),
        }, _ => _ != null)],
      };
    })
    try {
      await setDoc(doc(company.ref, 'dealRenewsJobs', [deal.id, detail.id].join('__')), {
        status: 'initial',
        type: 'add',
        dealId: deal.id,
        dealIssueDate: deal.issue_date,
        detailId: detail.id,
        renews: newData,
        updatedAt: new Date(),
        initializedAt: new Date(),
      });
      onClickClose();
    } catch(e) {
      console.error(e);
      toast.error('失敗しました');
    }
  };
  const onSubmitBatchUpdate = async (values, { onClickClose }) => {
    const newData = selectedItemIds.map((renewId) => {
      const renew = renewsById[renewId];
      return {
        id: renewId,
        values: {
          ...renew,
          ...(values.updateDate != null && { update_date: formatDate(values.updateDate, 'yyyy-MM-dd'), }),
          details: [pickBy({
            ...renew.details[0],
            ...pickBy({
              ...mapValues(pick(freeeFieldMapping, ['tax_code', 'account_item_id', 'section_id', 'item_id', 'tag_ids', 'segment_1_tag_id', 'segment_2_tag_id', 'segment_3_tag_id', 'description']), (v, k) => k === 'description' ? values[v] : numerize(values[v])),
              amount: values.amount,
            }, _ => _ != null),
          }, _ => _ != null)],
        },
      };
    })
    try {
      await setDoc(doc(company.ref, 'dealRenewsJobs', [deal.id, detail.id].join('__')), {
        status: 'initial',
        type: 'update',
        dealId: deal.id,
        dealIssueDate: deal.issue_date,
        detailId: detail.id,
        renews: newData,
        updatedAt: new Date(),
        initializedAt: new Date(),
      });
      setSelectedItemIds([]);
      onClickClose();
    } catch(e) {
      console.error(e);
      toast.error('失敗しました');
    }
  };

  return (
    <div className="position-relative">
      {
        isWaitingForJob && (
          <div className="alert alert-info">
            {dealRenewsJob.renews.length}件の{({ delete: '一括削除', add: '一括追加', update: '一括編集' })[dealRenewsJob.type]}の{statuses[dealRenewsJob.status].label}です。{dealRenewsJob.processedRenewIds != null && `(${dealRenewsJob.processedRenewIds.length}/${dealRenewsJob.renews.length})`}
          </div>
        )
      }
      {
        dealRenewsJob?.status === 'failed' && (
          <div className="alert alert-danger">
            処理に失敗しました。
            {
              dealRenewsJob.freeeErrorMessages?.map((message, i) => {
                return (
                  <li key={i}>
                    {message}
                  </li>
                );
              })
            }
          </div>
        )
      }
      <div className="mb-1 d-flex flex-column gap-1 position-relative" style={{ zIndex: 10 }}>
        <div className="d-flex align-items-end justify-content-start gap-1 position-relative">
          {
            entries(omit(companyFreeeMasters(company), ['partner'])).map(([name, { label, }]) => (
              <div key={name} style={{ width: 150, }}>
                <div className="text-muted small">{label}</div>
                <Select
                  isMulti
                  value={masterOptions[name].filter(_ => filterState[name]?.includes(_.value))}
                  options={masterOptions[name]}
                  onChange={_ => setFilterState({ ...filterState, [name]: (_ || []).map(_ => _.value), })}
                  className="w-100"
                  isClearable
                />
              </div>
            ))
          }
        </div>
        <div className="d-flex align-items-end justify-content-start gap-1 position-relative">
          {
            entries(dateRangeFilters).map(([name, { label }]) => (
              <div key={name}>
                <div className="text-muted small">{label}</div>
                <DateSelector
                  onlyYearMonth
                  value={filterState[name]}
                  onChange={_ => setFilterState({ ...filterState, [name]: _ })}
                  yearRange={(_ => [_, _ + 20])(new Date(deal.issue_date).getFullYear())}
                />
              </div>
            ))
          }
        </div>
      </div>
      <div className="d-flex justify-content-between align-items-end gap-1">
        <div>
          {
            isSelecting && (
              <div>
                <div className="d-flex gap-2 justify-content-between align-items-end">
                  <div>
                    {selectedItemIds.length} 件を選択中
                  </div>
                  <div className="d-flex gap-1">
                    <ProgressButton color="danger" process={onClickDelete}>
                      <span className="fas fa-trash mr-1" />
                      一括削除
                    </ProgressButton>
                    <ModalButton
                      color="primary"
                      Modal={ModelFormModal}
                      modalProps={{
                        fields: renewUpdateFields({ company, deal, detail, detailRenews, targetRenewIds: selectedItemIds, accountItems, taxes, sections, items, tags, segment1s, segment2s, segment3s, }),
                        title: '一括編集',
                        submitLabel: '一括編集する',
                        onSubmit: onSubmitBatchUpdate,
                        onStateChanged: onStateChanged.bind(null, accountItemsById),
                      }}
                    >
                      <span className="fas fa-edit mr-1" />
                      一括編集
                    </ModalButton>
                  </div>
                </div>
              </div>
            )
          }
        </div>
        <div className="d-flex gap-2 align-items-end">
          <div className="d-flex gap-1">
            <span className="font-weight-bold">+更新合計</span>
            <span>{numeral(renewsTotalAmount).format()}</span>
          </div>
          <div className="d-flex gap-1">
            <span className="font-weight-bold">残高</span>
            <span>{numeral(leftAmount).format()}</span>
          </div>
          <ModalButton
            color="primary"
            Modal={ModelFormModal}
            disabled={leftAmount === 0 || isWaitingForJob}
            modalProps={{
              fields: defermentFields({ company, deal, detail, leftAmountWithTax, accountItems, taxes, sections, items, tags, segment1s, segment2s, segment3s, }),
              renderFormHeader: ({ statedFields }) => <DefermentTemplateSelector className="mb-1" {...{ masters }} onSelect={_ => console.log(111, _) || statedFields.setValues(_)} />,
              title: '+更新を一括追加',
              submitLabel: '一括追加する',
              onSubmit: onSubmitBatchAdd,
              onStateChanged: onStateChanged.bind(null, accountItemsById),
            }}
          >
            +更新を一括追加
          </ModalButton>
        </div>
      </div>
      <div className="overflow-auto mt-1" style={{ maxHeight: 'calc(100vh - 300px)', }}>
        <table className="table sticky-table table-bordered">
          <thead className="thead-light text-center">
            <tr>
              <th>
                <Input type="checkbox" disabled={isWaitingForJob} className="position-relative m-0" checked={difference(filteredRowGroups.map(_ => _.id), selectedItemIds).length === 0} onChange={_ => _.target.checked ? setSelectedItemIds(filteredRowGroups.map(_ => _.id)) : setSelectedItemIds([])} />
              </th>
              <th style={{ minWidth: 120 }}>日</th>
              <th style={{ minWidth: 150 }}>勘定科目</th>
              <th style={{ minWidth: 120 }}>金額</th>
              <th style={{ minWidth: 120 }}>税区分</th>
              <th style={{ minWidth: 200 }}></th>
            </tr>
          </thead>
          <tbody>
            {
              rowGroups.map((rowGroup) => {
                const { id: renewId, renewDetailRows, filterRenewDetailRows, update_date, isLast, } = rowGroup;

                return (
                  <Fragment key={renewId}>
                    {
                      filterRenewDetailRows.map((renewDetailRow, i) => {
                        const { id, accountItem, tax, section, item, description, segment1, segment2, segment3, } = renewDetailRow;
                        return (
                          <tr key={id} className={classnames({ 'table-secondary': waitingJobRenewIds.includes(renewId), })}>
                            {
                              i === 0 && (
                                <Fragment>
                                  <td rowSpan={filterRenewDetailRows.length}>
                                    <Input type="checkbox" disabled={isWaitingForJob} className="position-relative m-0" checked={selectedItemIds.includes(renewId)} onChange={_ => selectedItemIds.includes(renewId) ? unselectItem(renewId) : selectItem(renewId)} />
                                  </td>
                                  <td rowSpan={filterRenewDetailRows.length}>{update_date}</td>
                                </Fragment>
                              )
                            }
                            <td>{accountItem?.name}</td>
                            <td className="text-right">{numeral(computeAmountWithTax(renewDetailRow, fiscalYear, detail.vat === 0) + (isLast ? adjustmentAmount : 0)).format()}</td>
                            <td>{tax?.name_ja}</td>
                            <td>
                              <div>
                                <span className="badge badge-item">{item?.name}</span>
                                <span className="badge badge-section">{section?.name}</span>
                                {renewDetailRow.tags.map((_, i) => <span key={i} className="badge badge-tag">{_?.name}</span>)}
                                <div className="small">{description}</div>
                                <span className="badge badge-segment1">{segment1?.name}</span>
                                <span className="badge badge-segment2">{segment2?.name}</span>
                                <span className="badge badge-segment3">{segment3?.name}</span>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    }
                  </Fragment>
                );
              })
            }
          </tbody>
        </table>
      </div>
    </div>
  );
};
