import { useState, useEffect } from 'react';
import { onSnapshot, } from 'firebase/firestore';

export default function useCollectionSubscription (ref, dependencies = [], { initialItems = [] } = {}) {
  const [items, setItems] = useState(initialItems);
  useEffect(() => {
    if(!ref) return;

    setItems(initialItems);
    const unsubscribe = onSnapshot(ref, ({ docs }) => {
      setItems(docs.map(_ => ({ ..._.data(), id: _.id, ref: _.ref, })));
    }, e => console.error(ref, e));
    return unsubscribe;
  }, dependencies);
  return items;
};
