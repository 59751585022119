import React, { Component } from 'react';

import CompanyPage from '../hocs/CompanyPage';

export default CompanyPage(function CompanyRoot (props) {
  return (
    <div className="company-root container">
      <div className="p-4 bg-white my-4">
        <div className="d-flex justify-content-center mb-3">
          <h4>ホーム</h4>
        </div>
      </div>
    </div>
  );
});
