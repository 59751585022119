const { isEmpty, } = require('lodash');

const { entries } = Object;

const statuses = {
  initial: {
    label: '処理待ち', color: 'secondary',
  }, 
  processing: {
    label: '処理中', color: 'info',
  },
  completed: {
    label: '完了', color: 'success',
  },
  failed: {
    label: '失敗', color: 'danger',
  },
};

exports.statuses = statuses;
