const { isEmpty, } = require('lodash');
const { addMonths, format: formatDate, parseISO, } = require('date-fns');

const { entries } = Object;

const fields = ({ company, accountItems, taxes, sections, items, tags, segment1s, segment2s, segment3s, }) => {
  return {
    name: {
      label: '名称',
      type: 'string',
      validations: {
        required: v => !isEmpty(v),
      },
    },
    months: {
      label: '按分期間(月)',
      type: 'integer',
      validations: {
        required: v => v != null,
        greaterThan0: v => v == null || v >= 1,
        lessThanOrEaualToX: v => v == null || v <= 120,
      },
      initialValue: 12,
      hint: '120まで指定できます',
    },
    startAfter: {
      label: '按分開始月(発生月から何ヶ月後)',
      type: 'integer',
      validations: {
        required: v => v != null,
        greaterThanOrEqualTo0: v => v == null || v >= 0,
        lessThanOrEaualToX: v => v == null || v <= 120,
      },
      initialValue: 0,
      hint: '120まで指定できます',
    },
    accountItemId: {
      label: '勘定科目',
      type: 'select',
      options: accountItems.map(_ => ({ label: _.name, value: _.id })),
    },
    taxId: {
      label: '税区分',
      type: 'select',
      options: taxes.filter(_ => _.available).map(_ => ({ label: _.name_ja, value: _.id })),
    },
    sectionId: {
      label: '部門',
      type: 'select',
      options: sections.map(_ => ({ label: _.name, value: _.id })),
    },
    itemId: {
      label: '品目',
      type: 'select',
      options: items.map(_ => ({ label: _.name, value: _.id })),
    },
    tagIds: {
      label: 'メモタグ',
      type: 'multiSelect',
      options: tags.map(_ => ({ label: _.name, value: _.id })),
    },
    ...(
      company.usesSegment1 && {
        segment1Id: {
          label: 'セグメント1',
          type: 'select',
          options: segment1s.map(_ => ({ label: _.name, value: _.id })),
        },
      }
    ),
    ...(
      company.usesSegment2And3 && {
        segment2Id: {
          label: 'セグメント2',
          type: 'select',
          options: segment2s.map(_ => ({ label: _.name, value: _.id })),
        },
        segment3Id: {
          label: 'セグメント3',
          type: 'select',
          options: segment3s.map(_ => ({ label: _.name, value: _.id })),
        },
      }
    ),
    description: {
      label: '備考',
      type: 'string',
    },
  };
};

exports.fields = fields;
