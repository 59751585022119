import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import React from 'react';

if(['production', 'staging'].includes(import.meta.env.VITE_ENV)) {
  Bugsnag.start({
    apiKey: 'fbdc10d419b648ed7ade1fa2ab4470af',
    plugins: [new BugsnagPluginReact(React)],
    releaseStage: import.meta.env.VITE_ENV || 'development',
  });
}
