import React, { useEffect, } from 'react';
import { httpsCallable } from 'firebase/functions';
import { collection, doc, getDoc, } from 'firebase/firestore';
import qs from 'qs';
import { toast } from 'react-toastify';

import firebase, { functions, db, } from '../../firebase';

const usersRef = collection(db, 'users');
const invitationsRef = collection(db, 'invitations');
const confirmInvitation = httpsCallable(functions, 'confirmInvitation');

export default function Confirmation (props) {
  const { user, history } = props;
  const confirm = async () => {
    const { token } = qs.parse(window.location.search.slice(1), { arrayLimit: Infinity });
    const ref = doc(invitationsRef, token);
    const invitationSnap = await getDoc(ref);
    if (!invitationSnap.exists()) return redirectToRoot('Invalid URL or expired');

    const { companyId, } = invitationSnap.data();
    if (user.companies && user.companies[companyId]) return redirectToRoot('すでに参加済みです');

    await confirmInvitation({ token });
    toast.success('参加しました');
    history.push(`/companies/${companyId}`);
  };
  const redirectToRoot = (errorMessage) => {
    toast.error(errorMessage);
    history.push('/');
  };
  useEffect(() => {
    confirm();
  }, []);

  return <div className="confirmation" />;
}
