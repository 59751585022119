import React, { useState, } from 'react';
import { updateDoc, setDoc, } from 'firebase/firestore';
import { toast } from 'react-toastify';
import { Button, } from 'reactstrap';

import useDocumentSubscription from './hooks/useDocumentSubscription';

export default function EditButton ({ label = '編集', type = 'update', icon = <span className="fas fa-edit" />, validateOnSubmit = _ => true, itemRef, FormModal, formProps, processValues = _ => _, onFinish = _ => _, ...extraProps }) {
  const [showsModal, setShowsModal] = useState(false);
  const item = useDocumentSubscription(showsModal && itemRef, [showsModal]);
  const onSubmit = async (values) => {
    if(!(await validateOnSubmit({ id: itemRef.id, ...values }))) return;

    try {
      const processedValues = await processValues(values);
      await ({ update: updateDoc, set: setDoc })[type](itemRef, processedValues);
      await onFinish({ id: itemRef.id, ...processedValues });
      toast.success('保存しました');
      setShowsModal(false);
    } catch(e) {
      console.error(e);
      toast.error('失敗しました');
    }
  };

  return (
    <Button color="secondary" onClick={_ => setShowsModal(true)} {...extraProps}>
      {icon}
      {label && <span className="ml-1">{label}</span>}
      {
        (item != null || (type === 'set' && item === null)) && (
          <FormModal isOpen={showsModal} values={item} onClickClose={_ => setShowsModal(false)} onSubmit={onSubmit} {...formProps} />
        )
      }
    </Button>
  );
};
