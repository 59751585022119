import React, { useState, } from 'react';
import fileDownload from 'js-file-download';
import { unparse as unparseCsv, } from 'papaparse';
import { isFunction } from 'lodash';
import classnames from 'classnames';
import { Button, } from 'reactstrap';

import { encode } from '../util';

export default function ExportButton ({ fileName, rows, encoding = 'SJIS', ...extraProps }) {
  const [isExporting, setIsExporting] = useState(false);

  const onClickExport = async () => {
    setIsExporting(true);
    const fileContent = encode(unparseCsv(isFunction(rows) ? (await rows()) : rows), encoding);
    fileDownload(fileContent, fileName);
    setIsExporting(false);
  };

  return (
    <Button color="secondary" onClick={onClickExport} disabled={isExporting} {...extraProps}>
      <span className={classnames('fas mr-1', { 'fa-spin fa-spinner': isExporting, 'fa-download': !isExporting })} />
      エクスポート
    </Button>
  );
};
