import React, { Fragment } from 'react';
import { httpsCallable } from 'firebase/functions';
import { deleteField, collection, doc, getDoc, query, where, orderBy, limit, addDoc, updateDoc, setDoc, } from 'firebase/firestore';
import { getAuth } from "firebase/auth";
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { isEmpty, omit, pickBy, groupBy, } from 'lodash';
import { useToggle, useAsync, } from 'react-use';
import classnames from 'classnames';
import { format as formatDate, startOfMonth, endOfMonth, addDays, endOfDay, } from 'date-fns';

import firebase, { functions, db, } from '../../firebase';
import { fieldsToAdd as fields, } from '../../shared/models/company';
import HeaderNav from '../HeaderNav';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useCollectionsFetch from '../hooks/useCollectionsFetch';
import FreeeCompaniesModal from '../modals/FreeeCompaniesModal';
import OverlayLoading from '../OverlayLoading';
import ModelFormModal from '../modals/ModelFormModal';
import NonFreeeCompanyFormModal from '../modals/NonFreeeCompanyFormModal';
import AddButton from '../AddButton';
import env from '../../env';

const getFreeeAuthUrl = httpsCallable(functions, 'getFreeeAuthUrl');
const getFreeeCompanies = httpsCallable(functions, 'getFreeeCompanies');
const auth = getAuth(firebase);
const { keys } = Object;

export default function Dashboard (props) {
  const { user, companies, } = props;
  const [isIntegrating, toggleIntegrating] = useToggle(false);
  const integrateWithFreee = async () => {
    toggleIntegrating(true);
    const { data: url } = await getFreeeAuthUrl();
    window.location.href = url;
  }
  const separateFromFreee = async () => {
    if (!window.confirm('本当に連携を解除しますか？')) return;

    await updateDoc(user.ref, { freee: deleteField() })
    toast.success('freeeとの連携を解除しました');
  };
  const { value: freeeCompanies = [] } = useAsync(async () => {
    const { data: { freeeCompanies = [] } } = await getFreeeCompanies({ includesExisting: false });
    return freeeCompanies;
  });
  const onFinishAdd = async (values) => {
    await setDoc(doc(db, `companies/${values.id}/syncCompanyJobs/0`), {
      status: 'initial',
      createdAt: new Date(),
      isInitialCompany: true,
    });
  };

  return (
    <div className="dashboard h-100">
      <HeaderNav {...props} />
      <div className="container">
        <div className="p-4 d-flex flex-column justify-content-center align-items-stretch">
          <div className="d-flex justify-content-end mb-3">
          </div>
          <div className="d-flex justify-content-end gap-1">
            {
              user.freee && (
                <AddButton
                  itemRef={doc(collection(db, 'companies'), )}
                  processValues={(values) => {
                    const freeeCompany = freeeCompanies.find(_ => _.id === values.sourceId);
                    return ({
                      ...values,
                      ...omit(freeeCompany, ['id']),
                      renewPlusTrialExpiredAt: endOfDay(addDays(new Date(), 30)),
                      createdAt: new Date(),
                      users: {
                        [user.id]: { role: 'owner', },
                      },
                      viaFreee: true,
                    });
                  }}
                  FormModal={ModelFormModal}
                  onFinish={onFinishAdd}
                  formProps={{ title: '事業所 追加', fields: fields({ freeeCompanies, }), }}
                  label="freeeから事業所を追加"
                />
              )
            }
            {
              !user.freee && (
                <Button color="primary" onClick={integrateWithFreee} disabled={isIntegrating}>
                  <span className={classnames('fas mr-1', { 'fa-link': !isIntegrating, 'fa-spin fas-spinner': isIntegrating })} />
                  freeeと連携する
                </Button>
              )
            }
            {
              user.freee && (
                <Button color="danger" onClick={separateFromFreee}>
                  <span className="fas fa-unlink mr-1" />freeeとの連携を解除する
                </Button>
              )
            }
          </div>
          <div className="mt-4">
            {
              (companies || []).length > 0 ? (
                <div className="d-flex flex-column gap-2">
                  {
                    companies.filter(_ => _.display_name).map((company) => {
                      const { display_name: name, id } = company;

                      return (
                        <div key={id} className="card position-relative">
                          <div className="card-body">
                            <div className="row">
                              <div className="col-12">
                                <Link className="h2" to={`/companies/${id}`}>{name}</Link>
                                <div className="mt-3 small" style={{ color: '#bbb', }}>事業所ID: {id}</div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  }
                </div>
              ) : (
                <div>事業所はまだありません。</div>
              )
            }
          </div>
        </div>
      </div>
    </div>
  );
};
