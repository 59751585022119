import React, { useState, useEffect, useMemo, } from 'react';
import { getAuth } from "firebase/auth";
import { collection, doc } from 'firebase/firestore';
import { sortBy, keyBy, range, inRange, get, pick, last, } from 'lodash';
import { useHistory, useParams, useLocation, } from 'react-router';
import { Nav, NavItem, } from 'reactstrap';
import { toast } from 'react-toastify';
import { format as formatDate } from 'date-fns';
import qs from 'qs';
import Select from 'react-select';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import { periodOfFiscalYear, fiscalYearOfPeriod, fullPathWithParams } from '../../utils';
import { accountItemCategoryNames, } from '../../shared/config';
import firebase, { db } from '../../firebase';
import CompanyPage from './CompanyPage';
import CompanySyncButton from '../CompanySyncButton';
import useCompanySelector from '../hooks/useCompanySelector';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useQueryParams from '../hooks/useQueryParams';
import QuerySelector from '../QuerySelector';

const auth = getAuth(firebase);
const companiesRef = collection(db, 'companies');
const tabs = [
  { name: 'general', label: '一般', path: '', isActive: _ => _.pathname.endsWith('settings') },
  { name: 'members', label: 'メンバー', path: '/members', isActive: _ => _.pathname.match(/members/) },
  { name: 'payment', label: 'お支払い', path: '/payment', isActive: _ => _.pathname.match(/payment/) },
];

export default function SettingsPageHOC(WrappedComponent) {
  return CompanyPage(function SettingsPage (props) {
    const { user, company, location, history, match: { params: { companyId, } }, } = props;

    return (user.admin || ['owner', 'admin'].includes(company.users?.[user.id].role)) && (
      <div className="settings-page h-100 container-fluid">
        <div className="p-4">
          <Nav tabs className="mt-4">
            {
              tabs.filter(_ => _.filter ? _.filter(company) : true).map(({ label, name, isActive, path, }) => {
                return (
                  <NavItem key={name}>
                    <Link className={classnames('nav-link cursor-pointer', { active: isActive(location) })} to={`/companies/${companyId}/settings${path}${location.search}`}>
                      {label}
                    </Link>
                  </NavItem>
                )
              })
            }
          </Nav>
          <div className="bg-white py-5">
            <WrappedComponent {...props}  />
          </div>
        </div>
      </div>
    );
  });
};
