const { pickBy, findKey, sortBy, isEmpty, } = require('lodash');
const { differenceInDays } = require('date-fns');

const { freeeMasters, accountItemCategoryNames, accountItemCategoriesByName, } = require('../config');
const { getDocumentData, getCollectionData, } = require('../firebase');

const currentYear = (new Date()).getFullYear();

const companyFreeeMasters = (company) => {
  return pickBy(freeeMasters, (v, k) => {
    return !k.includes('segment') || (k === 'segment1' && company.usesSegment1) || (['segment2', 'segment3'].includes(k) && company.usesSegment2And3);
  });
};

const { entries } = Object;

const getOwnerId = (company) => findKey(company?.users, { role: 'owner' });

const generalSettingsFields = () => {
  return {
    usesSegment1: {
      label: 'セグメント1の利用',
      type: 'boolean',
      initialValue: false,
      hint: 'freeeのプロフェッショナルプラン以上が必要です',
    },
    usesSegment2And3: {
      label: 'セグメント2, 3の利用',
      type: 'boolean',
      initialValue: false,
      hint: 'freeeのエンタープライズプランが必要です',
      hidden: _ => !_.usesSegment1,
    },
  };
};

const fieldsToAdd = ({ freeeCompanies = [] } = {}) => {
  return {
    sourceId: {
      label: 'freee事業所',
      type: 'select',
      options: freeeCompanies.map(_ => ({ label: _.display_name, value: _.id })),
      validations: {
        required: v => v != null,
      },
    },
  };
};

const adminFields = () => {
  return {
    licenseCount: {
      label: 'ライセンス数',
      type: 'integer',
      validations: {
        required: v => v != null,
        greaterThanOrEqualTo0: v => v == null || v >= 0,
      },
      initialValue: 5,
    },
  };
};

const nonFreeeFields = () => {
  return {
    display_name: {
      label: '事業所名',
      type: 'string',
      validations: {
        required: v => !isEmpty(v),
      },
    },
  };
};

const fiscalYearFields = () => {
  return {
    start_date: {
      label: '開始',
      type: 'date',
      selector: true,
      yearRange: [2018, currentYear + 5],
      inputProps: { onlyYearMonth: true },
      initialValue: null,
    },
    end_date: {
      label: '終了',
      type: 'date',
      selector: true,
      yearRange: [2018, currentYear + 5],
      inputProps: { onlyYearMonth: true },
      initialValue: null,
    },
  };
};

exports.fieldsToAdd = fieldsToAdd;
exports.adminFields = adminFields;
exports.nonFreeeFields = nonFreeeFields;
exports.fiscalYearFields = fiscalYearFields;
exports.generalSettingsFields = generalSettingsFields;
exports.getOwnerId = getOwnerId;
exports.companyFreeeMasters = companyFreeeMasters;
