import React from 'react';

export default function OverlayLoading (props) {
  const { label = '読込中...', isOpen = false } = props;

  return (
    <div className="d-flex justify-content-center align-items-center" style={{ 
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      background: '#fafafa',
      opacity: isOpen ? 0.9 : 0,
      transition: 'opacity 0.3s linear',
      pointerEvents: isOpen ? 'all' : 'none',
      zIndex: 10000,
    }}>
      <span className="fas fa-spin fa-spinner mr-1" />
      {label}
    </div>
  );
};
