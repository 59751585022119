import React from 'react';
import { isFunction, } from 'lodash';
import { toast } from 'react-toastify';
import { Button, } from 'reactstrap';
import { useToggle } from 'react-use';

export default function ProgressButton ({ hidesIcon = false, icon = null, label = '', children, process = _ => _, disabled, ...extraProps }) {
  const [isProcessing, toggleProcessing] = useToggle(false);
  const onClick = async () => {
    toggleProcessing(true);
    await process();
    toggleProcessing(false);
  };
  const content = label || (isFunction(children) ? children({ isProcessing }) : children);

  return (
    <Button onClick={onClick} disabled={isProcessing || disabled} {...extraProps}>
      {!hidesIcon && (isProcessing ? <span className="fas fa-spin fa-spinner mr-1" /> : (icon && <span className="mr-1">{icon}</span>))}
      {content}
    </Button>
  );
};
