import React, { useEffect, } from 'react';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter, Form, } from 'reactstrap';
import { mapValues, isFunction, } from 'lodash';
import { useToggle } from 'react-use';

import useFormState from '../hooks/useFormState';
import usePrevious from '../hooks/usePrevious';
import Field from '../Field';

const { entries } = Object;

export default function ModelFormModal(props) {
  const { submitLabel = '保存', fields, title, isOpen, values, renderFormHeader = _ => null, onClickClose, onStateChanged = _ => _, } = props;
  const statedFields = useFormState(values, isFunction(fields) ? fields() : fields, isOpen);
  const prevStatedFields = usePrevious(statedFields);
  const isUnsubmittable = Object.values(statedFields).some(_ => !_.isValid);
  const [isSubmitting, toggleSubmitting] = useToggle(false);
  const onSubmit = async (event) => {
    event.preventDefault();
    if(isUnsubmittable) return;

    toggleSubmitting(true);
    await props.onSubmit({ ...mapValues(statedFields, 'value'), }, { onClickClose });
    toggleSubmitting(false);
  };
  useEffect(() => {
    onStateChanged(prevStatedFields, statedFields);
  }, [...Object.values(mapValues(statedFields, 'value'))]);


  return (
    <Modal isOpen={isOpen}>
      <ModalHeader>
        {title}
      </ModalHeader>
      <Form onSubmit={onSubmit}>
        <ModalBody>
          {renderFormHeader({ statedFields, prevStatedFields })}
          {
            entries(statedFields).map(([fieldName, fieldSetting]) => (
              <Field
                key={fieldName}
                name={fieldName}
                values={mapValues(statedFields, 'value')}
                {...fieldSetting}
              />
            ))
          }
        </ModalBody>
        <ModalFooter>
          <Button className="cancel" color="secondary" disabled={isSubmitting} onClick={onClickClose}>閉じる</Button>
          <Button className="save" type="submit" color="primary" onClick={onSubmit} disabled={isUnsubmittable || isSubmitting}>
            {isSubmitting && <span className="fas fa-spin fa-spinner mr-1" />}
            {submitLabel}
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
};
