import React, { Fragment } from 'react';
import { omit, isFunction, } from 'lodash';
import { toast } from 'react-toastify';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter, Form, } from 'reactstrap';
import { useToggle, } from 'react-use';
import classnames from 'classnames';

export default function ModalButton (props) {
  const { color = 'secondary', content = _ => null, modalProps: _modalProps, title, label, children, ...extraProps } = props;
  const [showsModal, toggleModal] = useToggle(false);
  const modalProps = isFunction(_modalProps) ? _modalProps({ showsModal, toggleModal }) : _modalProps;

  return (
    <Fragment>
      <Button color={color} onClick={toggleModal.bind(null, true)} {...extraProps}>
        {children || label}
      </Button>
      {
        showsModal && (
          props.Modal ? (
            <props.Modal isOpen onClickClose={toggleModal.bind(null, false)} {...modalProps} />
          ) : (
            <Modal isOpen {...modalProps}>
              <ModalHeader>
                {title || label || children}
              </ModalHeader>
              <ModalBody>
                {content({ showsModal, toggleModal })}
              </ModalBody>
              <ModalFooter>
                <Button className="cancel" onClick={toggleModal.bind(null, false)}>閉じる</Button>
              </ModalFooter>
            </Modal>
          )
        )
      }
    </Fragment>
  );
};
