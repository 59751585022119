import React, { Fragment, useMemo, useState, useEffect, } from 'react';
import { httpsCallable, } from 'firebase/functions';
import { Button, Input, } from 'reactstrap';
import { collection, doc, getDoc, getDocs, query, where, orderBy, limit, addDoc, updateDoc, deleteDoc, setDoc, } from 'firebase/firestore';
import { toast } from 'react-toastify';
import { sum, sortBy, isArray, uniq, pickBy, pick, difference, last, range, groupBy, orderBy as _orderBy, sumBy, merge, mergeWith, uniqBy, keyBy, isEmpty, mapValues } from 'lodash';
import numeral from 'numeral';
import { format as formatDate, getYear, parseISO, isEqual as isEqualDate, startOfMonth, endOfMonth, addYears, addMonths, addHours, addMinutes, addSeconds, differenceInDays, eachMonthOfInterval, } from 'date-fns';
import { useToggle, useList, } from 'react-use';
import classnames from 'classnames';
import { useHistory, useLocation, } from 'react-router';
import { Link, } from 'react-router-dom';
import qs from 'qs';

import env from '../../env';
import { db, functions, batch, } from '../../firebase';
import { numerize, fiscalYearOfPeriod, yearMonthsOfPeriod, } from '../../shared/util';
import { jas, mapKeysToJa, } from '../../shared/texts';
import { freeeFieldMapping, accountItemCategoriesByName, } from '../../shared/config';
import { dealSettingFields, } from '../../shared/models/setting';
import { statuses, } from '../../shared/models/dealRenewsJob';
import { companyFreeeMasters, } from '../../shared/models/company';
import { computeAmountWithTax, batchFields, detailRowFilters, detailRowFilterOptionFilters, } from '../../shared/models/deferment';
import { onStateChanged, computeMonthLimit, computeLimitedDeals, } from '../../shared/models/deal';
import CompanyPage from '../hocs/CompanyPage';
import useQueryParams from '../hooks/useQueryParams';
import useDocumentSubscription from '../hooks/useDocumentSubscription';
import useCollectionSubscription from '../hooks/useCollectionSubscription';
import DefermentModalContent from '../modals/DefermentModalContent';
import DefermentTemplateSelector from '../DefermentTemplateSelector';
import ModelFormModal from '../modals/ModelFormModal';
import ExportButton from '../ExportButton';
import ProgressButton from '../ProgressButton';
import QuerySelector from '../QuerySelector';
import QueryBoolean from '../QueryBoolean';
import QueryDateRangeSelector from '../QueryDateRangeSelector';
import ModalButton from '../ModalButton';
import EditButton from '../EditButton';
import ThWithOrder from '../ThWithOrder';

const { floor } = Math;
const { keys, entries } = Object;
const leftColumnWidths = [ 24, 100, 120, 150, 120, 120, 200, ];
const syncDeals = httpsCallable(functions, 'syncDeals');
const fetchTrial = httpsCallable(functions, 'fetchTrial');
const refreshToken = httpsCallable(functions, 'refreshToken');

export default CompanyPage(function CompanyDeferments (props) {
  const { user, location, company, } = props;
  const queryParams = useQueryParams();
  const {
    accountItemIds,
    onlyLeftAmount,
    sortKey,
    sortDirection,
    displayDateRange,
    issueDateRange,
  } = queryParams;
  const displayStartOn = startOfMonth(queryParams.displayDateRange?.[0] ? new Date(queryParams.displayDateRange[0]) : addMonths(new Date(), -1));
  const displayEndOn = endOfMonth(queryParams.displayDateRange?.[1] ? new Date(queryParams.displayDateRange[1]) : addMonths(displayStartOn, 11));
  const issueStartOn = queryParams.issueDateRange?.[0] ? new Date(queryParams.issueDateRange[0]) : null;
  const issueEndOn = queryParams.issueDateRange?.[1] ? new Date(queryParams.issueDateRange[1]) : null;
  const [trialFetchingState, setTrialFetchingState] = useState({});
  const [trialsByMonth, setTrials] = useState({});
  const [selectedItemIds, { set: setSelectedItemIds, push: selectItem, removeAt: removeSelectedItemIdAt }] = useList([]);
  const unselectItem = _ => removeSelectedItemIdAt(selectedItemIds.indexOf(_));
  const isSelecting = selectedItemIds.length > 0;
  const [fiscalYear] = company.fiscalYears.slice(-1);
  const yearMonths = useMemo(() => {
    try {
      return eachMonthOfInterval({ start: displayStartOn, end: displayEndOn }).map(_ => endOfMonth(_));
    } catch(e) {
      return [];
    }
  }, [...(queryParams.displayDateRange || ['', ''])]);
  const syncDealsJobs = useCollectionSubscription(collection(company.ref, `syncDealsJobs`), [company]);
  const plans = useCollectionSubscription(query(collection(db, 'plans'), where('type', '==', 'renewPlus')));
  const [activeSubscription] = useCollectionSubscription(query(collection(company.ref, 'stripeSubscriptions'), where('type', '==', 'renewPlus'), where('source.status', '==', 'active'), where('source.canceled_at', '==', null)));
  const plan = plans.find(_ => _.id === activeSubscription?.planId) || plans.find(_ => _.isFree);
  const planMonthLimit = computeMonthLimit(plan, company);
  const dealSettingRef = doc(company.ref, 'settings', 'deal');
  const dealSetting = useDocumentSubscription(dealSettingRef);
  const renewPlusInformations = useCollectionSubscription(collection(company.ref, 'renewPlusInformations'), [company]);
  const mergedRenewPlusInformations = useMemo(_ => merge(...renewPlusInformations.map(_ => ({ ..._ }))), [renewPlusInformations]);
  const overMonths = sortBy(keys(pickBy(mergeWith(...Object.values(mergedRenewPlusInformations?.dealsCount || {}).map(_ => ({ ..._ })), (x = 0, y = 0) => x + y), v => v > planMonthLimit)), _ => _);
  const accountItems = useCollectionSubscription(collection(company.ref, 'accountItems'));
  const taxes = useCollectionSubscription(collection(company.ref, 'taxes'));
  const partnerChunks = useCollectionSubscription(collection(company.ref, 'partnerChunks'));
  const sectionChunks = useCollectionSubscription(collection(company.ref, 'sectionChunks'));
  const itemChunks = useCollectionSubscription(collection(company.ref, 'itemChunks'));
  const tagChunks = useCollectionSubscription(collection(company.ref, 'tagChunks'));
  const segment1Chunks = useCollectionSubscription(collection(company.ref, 'segment1Chunks'));
  const segment2Chunks = useCollectionSubscription(collection(company.ref, 'segment2Chunks'));
  const segment3Chunks = useCollectionSubscription(collection(company.ref, 'segment3Chunks'));
  const deals = useCollectionSubscription(!isEmpty(yearMonths) && query(collection(company.ref, 'deals'), where('runningYears', 'array-contains-any', uniq(yearMonths.map(_ => getYear(_))))), [yearMonths]);
  const dealRenewsJobs = useCollectionSubscription(query(collection(company.ref, 'dealRenewsJobs'), where('initializedAt', '>=', addHours(new Date(), -24))), [company]);
  const dealRenewsJobsGroupByDealId = groupBy(dealRenewsJobs, 'dealId');
  const limitedDeals = computeLimitedDeals(deals, plan, company);
  const accountItemsById = keyBy(accountItems, 'id');
  const taxesById = keyBy(taxes, 'id');
  const defermentAccountItems = (dealSetting?.defermentAccountItemIds || []).map(_ => accountItemsById[_]).filter(_ => _);
  const sectionsById = mapValues(merge(...sectionChunks.map(_ => _.data)), (v, k) => ({ ...v, id: k }));
  const partnersById = mapValues(merge(...partnerChunks.map(_ => _.data)), (v, k) => ({ ...v, id: k }));
  const itemsById = mapValues(merge(...itemChunks.map(_ => _.data)), (v, k) => ({ ...v, id: k }));
  const tagsById = mapValues(merge(...tagChunks.map(_ => _.data)), (v, k) => ({ ...v, id: k }));
  const segment1sById = mapValues(merge(...segment1Chunks.map(_ => _.data)), (v, k) => ({ ...v, id: k }));
  const segment2sById = mapValues(merge(...segment2Chunks.map(_ => _.data)), (v, k) => ({ ...v, id: k }));
  const segment3sById = mapValues(merge(...segment3Chunks.map(_ => _.data)), (v, k) => ({ ...v, id: k }));
  const sections = entries(sectionsById).map(([k, v]) => ({ id: k, ...v, }));
  const partners = entries(partnersById).map(([k, v]) => ({ id: k, ...v, }));
  const items = entries(itemsById).map(([k, v]) => ({ id: k, ...v, }));
  const tags = entries(tagsById).map(([k, v]) => ({ id: k, ...v, }));
  const segment1s = entries(segment1sById).map(([k, v]) => ({ id: k, ...v, }));
  const segment2s = entries(segment2sById).map(([k, v]) => ({ id: k, ...v, }));
  const segment3s = entries(segment3sById).map(([k, v]) => ({ id: k, ...v, }));
  const masters = {
    accountItems, taxes, partners, sections, items, tags, segment1s, segment2s, segment3s,
    accountItemsById, taxesById, partnersById, sectionsById, itemsById, tagsById, segment1sById, segment2sById, segment3sById,
  };
  const masterOptions = entries(companyFreeeMasters(company))
    .reduce((x, [name, { plural }]) => ({
      ...x,
      [name]: detailRowFilterOptionFilters[name](masters[plural], { dealSetting })
        .map(_ => ({ label: _.name_ja || _.name, value: _.id })),
    }), {});
  const generateRowGroups = (limitedDeals, defermentAccountItems, accountItemsById, taxesById, sectionsById, partnersById, itemsById, tagsById, segment1sById, segment2sById, segment3sById) => {
    return defermentAccountItems.map((defermentAccountItem) => {
      const relatedDeals = limitedDeals.filter(_ => _.accountItemIds?.includes(defermentAccountItem.id));
      const category = accountItemCategoriesByName[defermentAccountItem.account_category];
      const detailRows = _orderBy(relatedDeals.flatMap((deal) => {
        const issueMonth = endOfMonth(new Date(deal.issue_date));
        const partner = partnersById[deal.partner_id];
        const dealRenewsJobs = dealRenewsJobsGroupByDealId[deal.id];
        return deal.details
          .filter(_ => _.account_item_id.toString() === defermentAccountItem.id)
          .map((detail) => {
            const sign = category.direction === detail.entry_side ? 1 : -1;
            const accountItem = accountItemsById[detail.account_item_id];
            const tax = taxesById[detail.tax_code];
            const section = sectionsById[detail.section_id];
            const item = itemsById[detail.item_id];
            const tags = (detail.tag_ids || []).map(_ => tagsById[_]);
            const segment1 = segment1sById[detail.segment_1_tag_id];
            const segment2 = segment2sById[detail.segment_2_tag_id];
            const segment3 = segment3sById[detail.segment_3_tag_id];
            const detailRenews = deal.renews.filter(_ => _.renew_target_id === detail.id);
            const renewsTotalAmountWithTax = Math.min(detail.amount, sumBy(detailRenews.flatMap(_ => _.details), _ => _.amount * (_.entry_side === detail.entry_side ? 1 : -1)));
            const renewsTotalAmount = Math.min(computeAmountWithTax(detail, fiscalYear), sumBy(detailRenews.flatMap(_ => _.details), _ => computeAmountWithTax(_, fiscalYear, detail.vat === 0) * (_.entry_side === detail.entry_side ? 1 : -1)));
            const leftAmountWithTax = detail.amount - renewsTotalAmountWithTax;
            const leftAmount = computeAmountWithTax(detail, fiscalYear) - renewsTotalAmount;
            const dealRenewsJob = dealRenewsJobs?.find(_ => _.detailId === detail.id);
            const isWaitingForJob = dealRenewsJob?.status === 'initial' || dealRenewsJob?.status === 'processing' && (dealRenewsJob?.processStartedAt.toDate() >= addMinutes(new Date(), -10));
            const monthColumns = yearMonths.map((month) => {
              const isIssuedMonth = isEqualDate(issueMonth, month);
              const hasIssued = issueMonth <= month;
              const monthRenews = detailRenews.filter(_ => _.update_date.replace(/-/g, '').slice(0, 6) === formatDate(month, 'yyyyMM'));
              const oneYearRenews = detailRenews.filter(_ => formatDate(month, 'yyyyMM') < _.update_date.replace(/-/g, '').slice(0, 6) && _.update_date.replace(/-/g, '').slice(0, 6) <= formatDate(addMonths(month, 12), 'yyyyMM'));
              const prevAccumulatedRenews = detailRenews.filter(_ => _.update_date <= formatDate(endOfMonth(addMonths(month, -1)), 'yyyy-MM-dd'));
              const prevAccumulatedRenewsAmount = sumBy(prevAccumulatedRenews.flatMap(_ => _.details), _ => computeAmountWithTax(_, fiscalYear, detail.vat === 0) * (_.entry_side === detail.entry_side ? 1 : -1));
              // NOTE: 消費税がある場合のfreeeの+更新の最後の端数の合わせ方に合わせるため、以下Math.minとMath.maxが登場する。
              const leftAmount = Math.max(computeAmountWithTax(detail, fiscalYear) - prevAccumulatedRenewsAmount, 0);
              const monthRenewsAmount = Math.min(leftAmount, sumBy(monthRenews.flatMap(_ => _.details), _ => computeAmountWithTax(_, fiscalYear, detail.vat === 0) * (_.entry_side === detail.entry_side ? 1 : -1)));
              const accumulatedRenewsAmount = prevAccumulatedRenewsAmount + monthRenewsAmount;
              const balance = hasIssued ? Math.max(computeAmountWithTax(detail, fiscalYear) - accumulatedRenewsAmount, 0) : 0;
              const oneYearRenewsAmount = hasIssued ? Math.min(leftAmount, sumBy(oneYearRenews.flatMap(_ => _.details), _ => computeAmountWithTax(_, fiscalYear, detail.vat === 0) * (_.entry_side === detail.entry_side ? 1 : -1))) : 0;
              const notOneYearRenewsAmount = Math.max(balance - oneYearRenewsAmount, 0);
              return {
                month,
                isIssuedMonth,
                hasIssued,
                monthRenewsAmount,
                accumulatedRenewsAmount,
                balance,
                oneYearRenewsAmount,
                notOneYearRenewsAmount,
                sign,
              };
            });
            const monthColumnsByMonth = keyBy(monthColumns, _ => formatDate(_.month, 'yyyyMM'));
            const isAllNoBalance = monthColumns.every(_ => _.balance === 0 && _.monthRenewsAmount === 0);
            return {
              ...detail,
              sign,
              deal,
              partner,
              detailRenews,
              renewsTotalAmount,
              leftAmountWithTax,
              leftAmount,
              dealRenewsJob,
              isWaitingForJob,
              accountItem,
              tax,
              section,
              item,
              tags,
              segment1,
              segment2,
              segment3,
              monthColumns,
              monthColumnsByMonth,
              isAllNoBalance,
            };
          })
          .filter(_ => !_.isAllNoBalance);
      }), (row) => {
        return (queryParams.sortKey || 'issueDate') === 'issueDate' ? (
          row.deal.issue_date
        ) : (
          row.monthColumnsByMonth[queryParams.sortKey]?.balance
        );
      }, queryParams.sortDirection || 'asc');
      const filterDetailRows = (detailRows) => {
        let filteredDetailRows = detailRows;
        if(issueStartOn != null) {
          filteredDetailRows = filteredDetailRows.filter(_ => _.deal.issue_date >= formatDate(issueStartOn, 'yyyy-MM-dd'));
        }
        if(issueEndOn != null) {
          filteredDetailRows = filteredDetailRows.filter(_ => _.deal.issue_date <= formatDate(issueEndOn, 'yyyy-MM-dd'));
        }
        entries(companyFreeeMasters(company)).map(([k]) => {
          if(!isEmpty(queryParams[`${k}Ids`])) {
            filteredDetailRows = detailRowFilters[k](filteredDetailRows, queryParams[`${k}Ids`].map(_ => _ === '0' ? undefined : _));
          }
        });
        if(onlyLeftAmount === '1') {
          filteredDetailRows = filteredDetailRows.filter(_ => _.leftAmount !== 0);
        }
        return filteredDetailRows;
      };
      const filteredDetailRows = filterDetailRows(detailRows);
      return {
        defermentAccountItem,
        category,
        detailRows,
        filteredDetailRows,
      };
    }).filter(_ => _.detailRows.length > 0);
  };
  const rowGroups = generateRowGroups(limitedDeals, defermentAccountItems, accountItemsById, taxesById, sectionsById, partnersById, itemsById, tagsById, segment1sById, segment2sById, segment3sById);
  const allRows = rowGroups.flatMap(_ => _.detailRows);
  const rowsForExport = () => {
    return allRows.flatMap((detailRow) => {
      const { id, partner, sign, deal, dealRenewsJob, isWaitingForJob, detailRenews, renewsTotalAmount, leftAmount, leftAmountWithTax, accountItem, tax, section, item, segment1, segment2, segment3, description, amount, monthColumns, } = detailRow;
      return ['issueAmount', 'monthRenewsAmount', 'balance'].map((monthAmountType) => {
        return {
          issueDate: deal.issue_date,
          dealId: deal.id,
          accountItemName: accountItem?.name,
          issueAmount: computeAmountWithTax(detailRow, fiscalYear) * sign,
          leftAmount: leftAmount * sign,
          tax: tax?.name_ja,
          partner: partner?.name,
          item: item?.name,
          section: section?.name,
          tags: detailRow.tags.map(_ => _?.name).join(', '),
          segment1: segment1?.name,
          segment2: segment2?.name,
          segment3: segment3?.name,
          description,
          monthAmountType: jas[monthAmountType],
          ...monthColumns.reduce((x, monthColumn) => {
            const { month, isIssuedMonth, hasIssued, monthRenewsAmount, balance, } = monthColumn;
            return {
              ...x,
              [formatDate(month, 'yyyy/MM')]: ({
                issueAmount: _ => isIssuedMonth ? computeAmountWithTax(detailRow, fiscalYear) * sign : null,
                monthRenewsAmount: _ => hasIssued ? monthRenewsAmount * sign : null,
                balance: _ => hasIssued ? balance * sign : null,
              })[monthAmountType](),
            };
          }, {}),
        };
      });
    }).map(_ => mapKeysToJa(_));
  };
  const detailRowsById = keyBy(allRows, 'id');
  const selectableRows = rowGroups.flatMap(_ => _.filteredDetailRows.filter(_ => !_.isWaitingForJob));
  const onClickFetch = async () => {
    try {
      await refreshToken({ companyId: company.id, });
      await Promise.all(company.fiscalYears?.map(async (fiscalYear) => {
        const period = fiscalYear.start_date.replace(/-/g, '').slice(0, 6);
        await deleteDoc(doc(company.ref, `syncDealsJobs/${period}`));
        await setDoc(doc(company.ref, `syncDealsJobs/${period}`), {
          status: 'initial',
          fiscalYear,
          createdAt: new Date(),
        });
      }));
    } catch(e) {
      console.error(e);
      toast.error('失敗しました');
    }
  };
  const isSyncing = syncDealsJobs.some(_ => _?.status === 'initial' && (_?.createdAt.toDate() >= addMinutes(new Date(), -10)));
  const onSubmitBatchAdd = async (values, { onClickClose }) => {
    try {
      await batch(selectedItemIds, (batch, id) => {
        const detailRow = detailRowsById[id];
        const { deal, leftAmountWithTax, } = detailRow;
        const { months, startType, startAfter, startYearMonth, fractionPolicy, } = values;
        const startOn = {
          after: endOfMonth(addMonths(parseISO(deal.issue_date), startAfter)),
          yearMonth: endOfMonth(startYearMonth),
        }[startType];
        const newData = Array(months).fill().map((_, i) => {
          return {
            update_date: formatDate(endOfMonth(addMonths(startOn, i)), 'yyyy-MM-dd'),
            renew_target_id: id,
            details: [pickBy({
              amount: floor(leftAmountWithTax / months) + (i === ({ start: 0, end: months - 1 })[fractionPolicy] ? leftAmountWithTax % months : 0),
              ...['tax_code', 'account_item_id', 'section_id', 'item_id', 'tag_ids', 'segment_1_tag_id', 'segment_2_tag_id', 'segment_3_tag_id', 'description'].reduce((x, freeeFieldName) => {
                const value = values[freeeFieldMapping[freeeFieldName]] ?? detailRow[freeeFieldName];
                return {
                  ...x,
                  [freeeFieldName]: freeeFieldName === 'description' ? value : numerize(value),
                };
              }, {}),
            }, _ => _ != null)],
          };
        })
        batch.set(doc(company.ref, 'dealRenewsJobs', [deal.id, id].join('__')), {
          status: 'initial',
          type: 'add',
          dealId: deal.id,
          dealIssueDate: deal.issue_date,
          detailId: id,
          renews: newData,
          updatedAt: new Date(),
          initializedAt: new Date(),
        });
      });
      toast.success('+更新一括追加を開始しました');
      setSelectedItemIds([]);
      onClickClose();
    } catch(e) {
      console.error(e);
      toast.error('失敗しました');
    }
  };
  const onClickFetchTrial = async (month) => {
    if(!window.confirm('試算表残高を取得します。')) return;

    setTrialFetchingState({ ...trialFetchingState, [formatDate(month, 'yyyyMM')]: true });
    try {
      const { data: { trial, error: trialError, } } = await fetchTrial({ type: 'bs', companyId: company.id, startDate: formatDate(startOfMonth(month), 'yyyy-MM-dd'), endDate: formatDate(endOfMonth(month), 'yyyy-MM-dd'), });
      if(trialError) throw trialError;

      setTrials({ ...trialsByMonth, [formatDate(month, 'yyyyMM')]: { ...trial, balancesByName: keyBy(trial.balances, 'account_item_name'), }, });
    } catch(e) {
      console.error(e);
      toast.error('試算表残高の取得に失敗しました');
    }
    setTrialFetchingState({ ...trialFetchingState, [formatDate(month, 'yyyyMM')]: false });
  };
  const hasSyncDealsError = syncDealsJobs.some(_ => _?.status === 'failed' && _?.failedAt.toDate() > addSeconds(new Date(), -10));
  useEffect(() => {
    if(hasSyncDealsError) {
      toast.error('取引の取り込みに失敗しました');
    }
  }, [hasSyncDealsError]);

  return (
    <div className="company-deferments container-fluid">
      <div className="p-4 my-4">
        <div className="d-flex justify-content-center mb-1">
          <h4>+更新プラス</h4>
        </div>
        <div className="d-flex justify-content-end mb-1">
          <Link className="font-weight-bold" to={`/companies/${company.id}/renewPlusPlans${location.search}`}>
            {plan?.name}
            {
              plan?.isFree && new Date() <= company.renewPlusTrialExpiredAt?.toDate() && (
                <span className="ml-1 badge badge-info">試用期間残り{differenceInDays(company.renewPlusTrialExpiredAt.toDate(), new Date())}日</span>
              )
            }
          </Link>
        </div>
        {
          overMonths.length > 0 && (
            <div className="alert alert-warning">
              <div>
                以下でプランの上限を超える取引数が発生しているため、一部取り込めませんでした。
                <Link className="font-weight-bold small" to={`/companies/${company.id}/renewPlusPlans${location.search}`}>
                  プランを変更する
                  <span className="fas fa-angle-right ml-1" />
                </Link>
              </div>
              <div className="rounded p-2 d-flex justify-content-start gap-2 flex-wrap" style={{ background: '#ccc' }}>
                {
                  overMonths.map((overMonth) => {
                    const display = `${overMonth.slice(0, 4)}/${overMonth.slice(4)}`;
                    return (
                      <div key={overMonth}>
                        {display}
                      </div>
                    );
                  })
                }
              </div>
            </div>
          )
        }
        <div className="mb-1 d-flex align-items-end justify-content-start gap-1 position-relative">
          <QueryDateRangeSelector label="表示範囲" defaultValue={[displayStartOn, displayEndOn]} paramName="displayDateRange" pickerProps={{ showMonthYearPicker: true, dateFormat: 'yyyy/MM' }} />
        </div>
        <div className="mb-1 d-flex align-items-end justify-content-start gap-1 position-relative">
          <QueryDateRangeSelector label="発生日" defaultValue={[issueStartOn, issueEndOn]} paramName="issueDateRange" pickerProps={{ showYearDropdown: true, dropdownMode: 'select', }} />
          {
            entries(companyFreeeMasters(company)).map(([name, { label, }]) => (
              <QuerySelector key={name} paramName={`${name}Ids`} width={200} isMulti options={masterOptions[name]} label={label} />
            ))
          }
        </div>
        <div className="mb-1 d-flex align-items-end justify-content-start gap-1 position-relative">
          <QueryBoolean paramName="onlyLeftAmount" label="残高0を除く" defaultValue={'0'} />
        </div>
        <div className="mb-3 d-flex justify-content-between align-items-end gap-1">
          <div>
            {
              isSelecting && (
                <div>
                  <div className="d-flex gap-2 justify-content-between align-items-end">
                    <div>
                      {selectedItemIds.length} 件を選択中
                    </div>
                    <div className="d-flex gap-1">
                      <ModalButton
                        color="primary"
                        Modal={ModelFormModal}
                        modalProps={{
                          fields: batchFields({ ...masters, company, }),
                          renderFormHeader: ({ statedFields }) => <DefermentTemplateSelector className="mb-1" {...{ masters }} onSelect={_ => statedFields.setValues(_)} />,
                          title: '+更新を一括追加',
                          submitLabel: '一括追加する',
                          onSubmit: onSubmitBatchAdd,
                          onStateChanged: onStateChanged.bind(null, accountItemsById),
                        }}
                      >
                        +更新一括追加
                      </ModalButton>
                    </div>
                  </div>
                </div>
              )
            }
          </div>
          <div className="d-flex justify-content-end align-items-end gap-1">
            <div className="border rounded bg-white py-1 px-2 d-flex align-items-center gap-2">
              <div className="d-flex gap-1">
                {
                  dealSetting?.defermentAccountItemIds?.map((accountItemId) => {
                    const accountItem = accountItemsById[accountItemId];
                    return (
                      <div key={accountItemId}>
                        {accountItem?.name}
                      </div>
                    );
                  })
                }
              </div>
              <EditButton outline itemRef={dealSettingRef} label="対象科目" type="set" FormModal={ModelFormModal} formProps={{ title: '対象科目', fields: dealSettingFields({ accountItems }), }} />
            </div>
            <ExportButton fileName="＋更新.csv" rows={rowsForExport} />
            <ProgressButton color="primary" process={onClickFetch} disabled={!dealSetting?.defermentAccountItemIds?.length || isSyncing} hidesIcon>
              {isSyncing && <span className="mr-1 fas fa-spinner fa-spin" />}
              freeeから取引を取り込む
            </ProgressButton>
          </div>
        </div>
        <div>
          {
            allRows.length > 0 && (
              <div className="mt-0 overflow-auto" style={{ maxHeight: '90vh', }}>
                <table className="table sticky-table table-bordered position-relative table-hover bg-white rounded table-sm" style={{ zIndex: 0, borderCollapse: 'separate', borderSpacing: 0, }}>
                  <thead className="thead-light text-center align-top">
                    <tr>
                      {
                        [
                          <th key={0}>
                            <Input type="checkbox" className="position-relative m-0" checked={difference(selectableRows.map(_ => _.id), selectedItemIds).length === 0} onChange={_ => _.target.checked ? setSelectedItemIds(selectableRows.map(_ => _.id)) : setSelectedItemIds([])} />
                          </th>,
                          <th key={1}>+更新</th>,
                          <ThWithOrder sortKey="issueDate" key={2}>発生日</ThWithOrder>,
                          <th key={3}>勘定科目</th>,
                          <th key={4}>発生額 / 残高</th>,
                          <th key={5}>税区分</th>,
                          <th key={6}>
                            <div>&nbsp;</div>
                            <div className="d-flex justify-content-end">
                              <div className="small text-muted">
                                取引発生数
                              </div>
                            </div>
                          </th>,
                        ].map((el, i) => {
                          return {
                            ...el,
                            props: {
                              ...el.props,
                              className: 'sticky',
                              style: {
                                ...el.props.style,
                                minWidth: leftColumnWidths[i],
                                left: sum(leftColumnWidths.slice(0, i)) + 1,
                              },
                            },
                          };
                        })
                      }
                      {
                        yearMonths.map((month) => {
                          const count = sumBy(Object.values(mergedRenewPlusInformations?.dealsCount || {}), _ => _[formatDate(month, 'yyyyMM')] ?? 0);
                          const isOver = count > planMonthLimit;
                          return (
                            <ThWithOrder sortKey={formatDate(month, 'yyyyMM')} key={month.toString()} style={{ minWidth: 150 }}>
                              <div>{formatDate(month, 'yyyy-MM')}</div>
                              <div className="d-flex justify-content-end">
                                <div className="small text-muted">
                                  {isOver &&  <span className="fas fa-triangle-exclamation mr-1 text-warning" />}
                                  {numeral(count).format()}
                                </div>
                              </div>
                            </ThWithOrder>
                          );
                        })
                      }
                    </tr>
                  </thead>
                  {
                    rowGroups.map((rowGroup) => {
                      const { defermentAccountItem, category, detailRows, filteredDetailRows, } = rowGroup;

                      return (
                        <tbody key={defermentAccountItem.id}>
                          {
                            filteredDetailRows.map((detailRow) => {
                              const { id, partner, sign, deal, dealRenewsJob, isWaitingForJob, detailRenews, renewsTotalAmount, leftAmount, leftAmountWithTax, accountItem, tax, section, item, segment1, segment2, segment3, description, amount, monthColumns, } = detailRow;
                              const onClickDeleteDeal = async () => {
                                if(!window.confirm('取引を削除します。よろしいですか？')) return;

                                try {
                                  await deleteDoc(deal.ref);
                                  toast.success('削除しました');
                                } catch(e) {
                                  console.error(e);
                                  toast.error('失敗しました');
                                }
                              };
                              return (
                                <tr key={id}>
                                  {
                                    [
                                      <td key={0}>
                                        <Input type="checkbox" disabled={isWaitingForJob} className="position-relative m-0" checked={selectedItemIds.includes(id)} onChange={_ => selectedItemIds.includes(id) ? unselectItem(id) : selectItem(id)} />
                                        {user.dev && <a target="_blank" href={`https://console.firebase.google.com/u/0/project/${env('FIREBASE_PROJECT_ID')}/firestore/data/~2Fcompanies~2F${company.id}~2Fdeals~2F${deal.id}`}>F</a>}
                                      </td>,
                                      <td key={1}>
                                        <div className="d-flex flex-column align-items-center gap-1">
                                          <ModalButton color="primary" outline content={_ => <DefermentModalContent {...{ deal, detail: detailRow, dealRenewsJob, detailRenews, renewsTotalAmount, leftAmount, leftAmountWithTax, fiscalYear, masters, masterOptions, }} />} modalProps={{ style: { minWidth: 1100 }, }}>
                                            +更新
                                          </ModalButton>
                                          <div className={`text-${statuses[dealRenewsJob?.status]?.color}`}>
                                            {statuses[dealRenewsJob?.status]?.label}
                                          </div>
                                        </div>
                                      </td>,
                                      <td key={2}>
                                        <div>{deal.issue_date}</div>
                                        <span className="badge badge-id">{deal.id}</span>
                                        {
                                          deal.notExistsInFreee ? (
                                            <div>
                                              <div className="text-danger small">
                                                取引がfreeeに存在しません
                                              </div>
                                              <ProgressButton color="link" size="sm" process={onClickDeleteDeal}>
                                                <span className="fas fa-times text-secondary cursor-pointer">
                                                  削除
                                                </span>
                                              </ProgressButton>
                                            </div>
                                          ) : (
                                            <a className="small" href={`https://secure.freee.co.jp/deals#deal_id=${deal.id}`} target="_blank">
                                              freee
                                              <span className="fas fa-external-link-alt ml-1" />
                                            </a>
                                          )
                                        }
                                      </td>,
                                      <td key={3}>{accountItem?.name}</td>,
                                      <td className="text-right" key={4}>
                                        <div>{numeral(computeAmountWithTax(detailRow, fiscalYear) * sign).format()}</div>
                                        <div>{numeral(leftAmount * sign).format()}</div>
                                      </td>,
                                      <td key={5}>{tax?.name_ja}</td>,
                                      <td key={6}>
                                        <div>
                                          <span className="badge badge-partner">{partner?.name}</span>
                                          <span className="badge badge-item">{item?.name}</span>
                                          <span className="badge badge-section">{section?.name}</span>
                                          {detailRow.tags.map(_ => <span key={_?.id} className="badge badge-tag">{_?.name}</span>)}
                                          <span className="badge badge-segment1">{segment1?.name}</span>
                                          <span className="badge badge-segment2">{segment2?.name}</span>
                                          <span className="badge badge-segment3">{segment3?.name}</span>
                                        </div>
                                        <div className="small">{description}</div>
                                      </td>,
                                    ].map((el, i) => {
                                      return {
                                        ...el,
                                        props: {
                                          ...el.props,
                                          className: 'sticky',
                                          style: {
                                            ...el.props.style,
                                            minWidth: leftColumnWidths[i],
                                            left: sum(leftColumnWidths.slice(0, i)) + 1,
                                          },
                                        },
                                      };
                                    })
                                  }
                                  {
                                    monthColumns.map((monthColumn) => {
                                      const { month, isIssuedMonth, hasIssued, monthRenewsAmount, balance, } = monthColumn;
                                      return (
                                        <td key={month.toString()} className="text-right">
                                          <div>{isIssuedMonth ? numeral(computeAmountWithTax(detailRow, fiscalYear) * sign).format() : '-'}</div>
                                          <div>{hasIssued ? numeral(monthRenewsAmount * sign).format() : '-'}</div>
                                          <div>{hasIssued ? numeral(balance * sign).format() : '-'}</div>
                                        </td>
                                      );
                                    })
                                  }
                                </tr>
                              );
                            })
                          }
                          <tr className="font-weight-bold">
                            <td className="text-right sticky" colSpan={7}>
                              <div>{defermentAccountItem?.name} 残高計</div>
                              <div className="small text-muted">1年内</div>
                              <div className="small text-muted">1年超</div>
                              <div className="small text-muted">試算表残高</div>
                            </td>
                            {
                              yearMonths.map((month, i) => {
                                const totalBalance = sumBy(detailRows, _ => _.monthColumns[i].balance * _.sign);
                                const totalOneYearRenewsAmount = sumBy(detailRows, _ => _.monthColumns[i].oneYearRenewsAmount * _.sign);
                                const totalNotOneYearRenewsAmount = sumBy(detailRows, _ => _.monthColumns[i].notOneYearRenewsAmount * _.sign);
                                const trial = trialsByMonth[formatDate(month, 'yyyyMM')];
                                const trialBalance = trial?.balancesByName[defermentAccountItem?.name];
                                const isEqualWithTrial = totalBalance === (trialBalance?.closing_balance || 0);
                                const isFetchingTrial = !!trialFetchingState[formatDate(month, 'yyyyMM')];
                                return (
                                  <td key={month.toString()} className="text-right">
                                    <div className="d-flex gap-1 align-items-center justify-content-end">
                                      {
                                        trial != null && (
                                          isEqualWithTrial ? <span className="text-success fa fa-check" /> : <span className="text-danger fa fa-times" />
                                        )
                                      }
                                      <div>{numeral(totalBalance).format()}</div>
                                    </div>
                                    <div className="small text-muted">
                                      {numeral(totalOneYearRenewsAmount).format()}
                                    </div>
                                    <div className="small text-muted">
                                      {numeral(totalNotOneYearRenewsAmount).format()}
                                    </div>
                                    <div className="d-flex gap-1 align-items-center justify-content-end">
                                      <span className={classnames('fas fa-cloud-arrow-down text-secondary cursor-pointer', { 'fa-beat': isFetchingTrial })} onClick={onClickFetchTrial.bind(null, month)} />
                                      <div className="small text-muted">
                                        {trial != null ? numeral(trialBalance?.closing_balance).format() : '-'}
                                      </div>
                                    </div>
                                  </td>
                                );
                              })
                            }
                          </tr>
                        </tbody>
                      );
                    })
                  }
                </table>
              </div>
            )
          }
        </div>
      </div>
    </div>
  );
});
