import React, { useEffect } from 'react';
import { collection, doc, setDoc, deleteDoc, } from 'firebase/firestore';
import { useToggle } from 'react-use';
import { findKey } from 'lodash';
import { Button } from 'reactstrap';
import classnames from 'classnames';
import { addMinutes } from 'date-fns';
import { toast } from 'react-toastify';

import firebase, { functions } from '../firebase';
import useDocumentSubscription from './hooks/useDocumentSubscription';
import useCompany from './hooks/useCompany';

export default function CompanySyncButton (props) {
  const { ...extraProps } = props;
  const company = useCompany();
  const syncCompanyJobRef = doc(company.ref, 'syncCompanyJobs/0'); 
  const syncCompanyJob = useDocumentSubscription(syncCompanyJobRef);
  const isSyncing = syncCompanyJob?.status === 'initial' && (syncCompanyJob?.createdAt.toDate() >= addMinutes(new Date(), -10));
  const onClick = async () => {
    try {
      await deleteDoc(syncCompanyJobRef);
      await setDoc(syncCompanyJobRef, {
        status: 'initial',
        createdAt: new Date(),
      });
    } catch(e) {
      console.error(e);
      toast.error('失敗しました');
    }
  };
  useEffect(() => {
    if(syncCompanyJob?.status === 'failed' && syncCompanyJob?.failedAt.toDate() > addMinutes(new Date(), -1)) {
      toast.error('事業所同期に失敗しました');
    }
  }, [syncCompanyJob]);

  return (
    <Button color="secondary" disabled={isSyncing} onClick={onClick} {...extraProps}>
      <span className={classnames('mr-1 fas fa-sync cursor-pointer', { 'fa-spin': isSyncing })} />
      事業所を同期
    </Button>
  );
};
